import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { getStatusLabel } from 'src/Utils/Combined';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { IRaffleDrawAll } from './raffledrawTypes';
import moment from 'moment';

type IProps = {
  payment: IRaffleDrawAll;
  setAllPayment: any;
  allPayment: any;
};

const DrawTableRow = ({ payment }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {payment?.wining_position}
          </Typography>
        </TableCell>
        <TableCell>
          {' '}
          {moment(payment.wining_date).tz('GMT').format('YYYY-MM-DD')}
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {payment?.visitor_first_name} {payment?.visitor_last_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {payment?.visitor_id}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabel(payment.status === 1 ? 'Active' : 'Inactive')}
        </TableCell>
      </TableRow>
    </>
  );
};

export default DrawTableRow;
