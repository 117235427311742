import { ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IFairVisitorList } from '../Types/AllReportTypes';
import EventAttendTableRow from './EventAttendTableRow';

const applyPagination = (
  entryFairVisitorList: IFairVisitorList[],
  page: number,
  limit: number
): IFairVisitorList[] => {
  return entryFairVisitorList.slice(page * limit, page * limit + limit);
};

const EventAttendTable = ({ entryFairVisitorList, loading }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedInvoice = applyPagination(entryFairVisitorList, page, limit);
  return (
    <Card>
      <CardHeader title='Fair Event Attend Visitor List' />
      {}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>fair event attend id</TableCell>
              <TableCell>fair event attend at</TableCell>
              <TableCell>fair event joined visitor id</TableCell>
              <TableCell>visitor first name</TableCell>
              <TableCell>fair event joined visitor join date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : paginatedInvoice.length ? (
              <>
                {paginatedInvoice.map((fairVisitor: any) => {
                  return (
                    <EventAttendTableRow
                      key={fairVisitor.fair_event_attend_id}
                      fairVisitor={fairVisitor}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={entryFairVisitorList.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default EventAttendTable;
