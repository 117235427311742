import { Card, Container, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { url } from 'src/Helpers/utils/Constant';
import { visitorList } from 'src/Utils/type';
import VisitorTable from './VisitorTable';

const VisitorList = () => {
  const [fairAllMember, setFairAllMember] = useState<visitorList[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('all');
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/fair/visitor/for-admin/get/all/${status}`)
          .then(function (res) {
            if (res.data.success) {
              setFairAllMember(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setFairAllMember([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [status, reload]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Helmet>
            <title>ATF - Visitor list</title>
          </Helmet>
          <Card>
            <VisitorTable
              loading={loading}
              fairAllMember={fairAllMember}
              setStatus={setStatus}
              status={status}
              setReload={setReload}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VisitorList;
