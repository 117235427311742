import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IEntryFairList } from 'src/Utils/type';
import dayjs from 'dayjs';

type IProps = {
  entryFair: IEntryFairList;
};

const EntryFairTableRow = ({ entryFair }: IProps) => {
  console.log(entryFair);
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {entryFair.fair_entry_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {entryFair.visitor_first_name
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {dayjs(entryFair.entry_time).format('YYYY-MM-DD')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {dayjs(entryFair.entry_time).format('hh:mm:ss a')}
        </Typography>
      </TableCell>

      <TableCell>
        <Tooltip title='view' arrow>
          <Link to={`/visitor/list/${entryFair.visitor_id.toString()}`}>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color='inherit'
              size='small'
            >
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default EntryFairTableRow;
