import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import {
  getStatusLabelFairStall,
  getStatusLabelInvoiceStatus,
} from 'src/Utils/Combined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import moment from 'moment';
import axios from 'axios';
import Toaster from 'src/Toaster/Toaster';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmation from 'src/Utils/DeleteConfirmation';
import { useState } from 'react';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import FairInvoiceUpdateForm from './FairInvoiceUpdateForm';

const FairInvoiceByFairMemberTableRow = ({ allAdmin, setReload }: any) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const Toast = Toaster();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDelete = async (itemId: any) => {
  //   if (allAdmin.fair_invoice_id && user.user_admin_id) {
  //     try {
  //       const { data } = await axios.delete(
  //         `${url}/api/fair/stall/delete/${allAdmin.fair_invoice_id}`
  //       );
  //       { data });
  //       if (data.success) {
  //         Toast.fire({
  //           icon: "success",
  //           title: "Fair stall succesfully deleted",
  //         });
  //         setReload((prev: Boolean) => !prev);
  //         setOpen(false);
  //       }
  //     } catch (err) {
  //       Toast.fire({
  //         icon: "error",
  //         title: `Fair stall doesn't delete`,
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <TableRow hover key={allAdmin.fair_invoice_id}>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.invoice_issue_date).format('LL')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.total_amount}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_payment_method}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.fair_invoice_transaction_date).format('LL')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_transaction_id}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabelInvoiceStatus(allAdmin.fair_invoice_status)}
          {/* {allAdmin.fair_invoice_status} */}
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_updated_by
              ? allAdmin.fair_invoice_updated_by
              : 'Null'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_file ? (
              <a href={`${imgUrl}/company_files/${allAdmin.fair_invoice_file}`}>
                {allAdmin.fair_invoice_file}
              </a>
            ) : (
              'Null'
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_updated_at
              ? allAdmin.fair_invoice_updated_at
              : 'Null'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_remark
              ? allAdmin.fair_invoice_remark
              : 'Null'}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title='Delete' arrow>
            <IconButton
              // onClick={handleClickOpen}
              sx={{
                '&:hover': { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color='inherit'
              size='small'
            >
              <DeleteTwoToneIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Edit fair invoice'>
            <IconButton>
              <EditIcon onClick={handleOpen} sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {/* <DeleteConfirmation
        open={open}
        handleClose={handleClose}
        text="Are you want delete this fair invoice?"
        handleDeleteFun={handleDelete}
      /> */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          <Typography variant='h4'>Update Invoice Item</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FairInvoiceUpdateForm
              details={allAdmin}
              setReload={setReload}
              handleClose={handleClose}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FairInvoiceByFairMemberTableRow;
