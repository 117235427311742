import { Button } from "@mui/material";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

export const applyPagination = (
  allMembers: any[] = [],
  page: number,
  limit: number
) => {
  return allMembers?.slice(page * limit, page * limit + limit);
};

export const RefreshButton = ({
  action,
}: {
  action: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Button
      onClick={() => action((value: boolean) => !value)}
      variant='outlined'
      size='small'
    >
      Refresh
      <RefreshIcon />
    </Button>
  );
};
