import {
  alpha,
  Badge,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useRef } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { useState } from 'react';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

interface INotification {
  notification_id: number;
  notification_type: string;
  related_id: number;
  message: string;
  read_status: number;
  created_at: string;
  member_id?: number;
}

interface INotifications {
  notification: INotification[];
  count: number;
}

interface IProps {
  notification: INotifications;
  clearData: (
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  readNoti: (
    id: number,
    read_status: number,
    type: string,
    related_id: number,
    memberId?: number
  ) => void;
  setOpen: (value: boolean) => void;
  isOpen: boolean;
  loading: any;
}

function HeaderNotifications({
  notification,
  clearData,
  readNoti,
  setOpen,
  isOpen,
  loading,
}: IProps) {
  const ref = useRef<any>(null);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Tooltip arrow title='Notifications'>
        <IconButton color='primary' ref={ref} onClick={() => setOpen(true)}>
          <NotificationsBadge
            badgeContent={notification.count}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        sx={{ mt: 7, height: '400px', width: '400px' }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{ p: 2 }}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h5'>Notifications</Typography>

          {notification.notification.length ? (
            <Typography
              variant='h5'
              onClick={() => setOpenModal(true)}
              sx={{ cursor: 'pointer' }}
            >
              Clear all
            </Typography>
          ) : (
            ''
          )}
        </Box>
        <Divider sx={{ mx: 3 }} />
        <List sx={{ minWidth: 350 }}>
          {loading.dataLoading ? (
            <Box textAlign={'center'}>
              <CircularProgress disableShrink thickness={3} />
            </Box>
          ) : (
            <>
              {notification?.notification.length ? (
                <>
                  {notification?.notification.map((noti) => {
                    return (
                      <ListItem disablePadding key={noti.notification_id}>
                        <ListItemButton
                          sx={{ borderLeft: '1px solid #0c4da2', my: 0.4 }}
                          onClick={() => {
                            readNoti(
                              noti.notification_id,
                              noti.read_status,
                              noti.notification_type,
                              noti.related_id,
                              Number(noti.message.split(':')[1])
                            );
                          }}
                        >
                          <Box>
                            <Typography
                              className={
                                noti.read_status
                                  ? 'notification-read'
                                  : 'notification-unread'
                              }
                            >
                              {noti.message}
                            </Typography>
                            <Typography
                              className={
                                noti.read_status
                                  ? 'notification-read'
                                  : 'notification-unread'
                              }
                              sx={{ textTransform: 'none', pt: 0.5 }}
                            >
                              {moment(noti.created_at).fromNow()}
                            </Typography>
                          </Box>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </>
              ) : (
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  No data found
                </Typography>
              )}
            </>
          )}
        </List>
      </Popover>

      <ModalsWrapper
        modalData={{ title: 'Clear notification' }}
        setShowModal={setOpenModal}
        showModal={openModal}
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure you want to clear all notification?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading.clearLoading ? (
            <Button color='primary' autoFocus disabled>
              Loading...
            </Button>
          ) : (
            <Button
              color='primary'
              variant='outlined'
              size='small'
              autoFocus
              onClick={() => clearData(setOpenModal)}
            >
              Yes
            </Button>
          )}

          <Button
            color='error'
            size='small'
            variant='outlined'
            onClick={() => setOpenModal(false)}
          >
            No
          </Button>
        </DialogActions>
      </ModalsWrapper>
    </>
  );
}

export default HeaderNotifications;
