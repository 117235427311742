import React, { useContext, useReducer, useState, useEffect } from 'react';
import { reducer } from 'src/Reducers/AuthReducer/ModalReducer';
import { ImodalInitialState } from 'src/Types/module/utils/ModalTypes';

const initialState: ImodalInitialState = {
  showModal: false,
  modalData: {
    title: '',
  },
};

const ModalContext = React.createContext(initialState);

const ModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <ModalContext.Provider value={{ ...state, dispatch }}>
        {children}
      </ModalContext.Provider>
    </>
  );
};

const useModalContext = () => {
  return useContext(ModalContext);
};

export { ModalContextProvider, useModalContext };
