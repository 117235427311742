import { Box, Button, Grid, Typography } from '@mui/material';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { Iadmin } from 'src/Types/module/admin/adminTypes';
import { getStatusLabel } from 'src/Utils/Combined';

const SingleDetailsinfo = ({ singleAdmin }: { singleAdmin: Iadmin }) => {
  const {
    user_admin_id,
    user_admin_email,
    user_admin_photo,
    user_admin_role_name,
    user_admin_status,
    user_admin_phone,
    user_admin_name,
    // user_admin_zone_name,
  } = singleAdmin || {};
  return (
    <Box sx={{ mt: 3 }}>
      <Grid
        container
        spacing={{ lg: 5, md: 3, sm: 2 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
      >
        <Grid lg={4} item>
          <img
            width={150}
            height={200}
            style={{
              borderRadius: '10px',
            }}
            src={
              user_admin_photo
                ? `${imgUrl}/admin_files/${user_admin_photo}`
                : '/static/images/avatars/avatar2.png'
            }
            alt=''
          />
        </Grid>
        <Grid lg={8} sm={4} md={4} item>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid xs={12} sm={6} md={6} item>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                ID:
              </Typography>
              {user_admin_id}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Name:
              </Typography>
              {user_admin_name}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Role:
              </Typography>
              {user_admin_role_name}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Status:
              </Typography>
              {getStatusLabel(user_admin_status)}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Phone:
              </Typography>
              {user_admin_phone}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Email:
              </Typography>
              {user_admin_email}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleDetailsinfo;
