import { Card, Container, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import ExhibitorInvoiceTable from './ExhibitorInvoiceTable';
import { exhibitorInvoice } from 'src/Utils/type';

const ExhibitorInvoice = () => {
  const [exhibitorInvoice, setExhibitorInvoice] = useState<exhibitorInvoice[]>(
    []
  );
  const [reload, setReload] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('all');
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(
            `${url}/api/atab/fair-invoice/get/all/for-admin/status/${status}`
          )
          .then(function (res) {
            if (res.data.success) {
              setExhibitorInvoice(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setExhibitorInvoice([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status, reload]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <ExhibitorInvoiceTable
              loading={loading}
              allAdmin={exhibitorInvoice}
              setExhibitorInvoice={setExhibitorInvoice}
              setStatus={setStatus}
              status={status}
              setReload={setReload}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExhibitorInvoice;
