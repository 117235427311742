import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
} from '@mui/material';
import { url } from 'src/Helpers/utils/Constant';
import { IFairVisitorList } from '../Types/AllReportTypes';
import FairEntryTable from './FairEntryTable';
import ExcelGenerator from 'src/components/ExcelGenerator/ExcelGenerator';

const FairEntry = () => {
  const [entryFairEntryList, setEntryFairEntryList] = useState<
    IFairVisitorList[] | null
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  useEffect(() => {
    if (!initialFetchDone) {
      setLoading(true);
      const cancelTokenSource = axios.CancelToken.source();

      fetchFairEntryData()
        .then((data) => {
          if (data !== null) {
            setEntryFairEntryList(data);
          }
          setLoading(false);
          setInitialFetchDone(true);
        })
        .catch((err) => {
          setLoading(false);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    } else if (fromDate && toDate) {
      // Fetch data when both fromDate and toDate have values
      setLoading(true);
      const cancelTokenSource = axios.CancelToken.source();

      fetchFairEntryData()
        .then((data) => {
          if (data !== null) {
            setEntryFairEntryList(data);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [fromDate, toDate, initialFetchDone]);

  const fetchFairEntryData = async () => {
    let apiUrl = `${url}/api/fair/report/fair-entry`;

    if (fromDate && toDate) {
      apiUrl += `?from_date=${fromDate}&to_date=${toDate}`;
    }

    try {
      const response = await axios.get(apiUrl);
      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      } else {
        throw error;
      }
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                    alignItems: 'start',
                  }}
                >
                  <ExcelGenerator getDownloadData={fetchFairEntryData} />
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='by_date'
                    label='Select From Date'
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='by_date'
                    label='Select To Date'
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Box>
              }
              title='Fair Entry List'
            />
            <FairEntryTable
              loading={loading}
              entryFairEntryList={entryFairEntryList}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FairEntry;
