import React from 'react';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { getStatusLabel } from 'src/Utils/Combined';
import { IGuest } from 'src/Types/module/guestAndSpecker/guestAndSpeaker';
import moment from 'moment-timezone';
import { ISingleLead } from './types/LeadType';

interface IProps {
  lead: ISingleLead;
}

const SingleLeadDetails = ({ lead }: IProps) => {
  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Grid spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            gutterBottom
          >
            Lead ID: {lead?.lead_id}
          </Typography>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            gutterBottom
          >
            Name: {lead?.name}
          </Typography>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            gutterBottom
          >
            Email: {lead?.email}
          </Typography>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            gutterBottom
          >
            Phone: {lead?.phone}
          </Typography>
          <Typography variant='h6' gutterBottom>
            Lead Remarks:
          </Typography>
          <Typography variant='body1'>{lead?.lead_remarks}</Typography>
          <Divider sx={{ my: 3 }} />

          <Typography variant='h6' gutterBottom>
            Bussiness Card
          </Typography>

          {/* Business Card Images */}
          {lead?.business_card_front && lead?.business_card_back && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div>
                  <Typography variant='h6' gutterBottom>
                    Business Card Front
                  </Typography>
                  <img
                    src={`${imgUrl}/visitor_files/${lead?.business_card_front}`}
                    alt='Business Card Front'
                    style={{
                      width: '50%',
                      height: 'auto',
                      maxHeight: 400,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Typography variant='h6' gutterBottom>
                    Business Card Back
                  </Typography>
                  <img
                    src={`${imgUrl}/visitor_files/${lead?.business_card_back}`}
                    alt='Business Card Back'
                    style={{
                      width: '50%',
                      height: 'auto',
                      maxHeight: 400,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          )}

          <Divider sx={{ my: 3 }} />
          <Typography variant='h6' gutterBottom>
            Products:
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product ID</TableCell>
                  <TableCell>Product Name</TableCell>
                  {/* <TableCell>Price</TableCell> */}
                  <TableCell>Item Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lead?.products.map((product: any) => (
                  <TableRow key={product.fair_member_product_id}>
                    <TableCell>{product.fair_member_product_id}</TableCell>
                    <TableCell>{product.fair_member_product_name}</TableCell>
                    {/* <TableCell>{product.fair_member_product_price}</TableCell> */}
                    <TableCell>
                      {product.user_fair_member_product_type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleLeadDetails;
