import React, { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  Container,
  Grid,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IFairAllEvents } from 'src/Utils/type';
import EventTableRow from './EventTableRow';
import CreateEvent from '../CreateEvent/CreateEvent';
import { useSearchParams } from 'react-router-dom';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';

const applyPagination = (
  allMembers: IFairAllEvents[],
  page: number,
  limit: number
): IFairAllEvents[] => {
  return allMembers?.slice(page * limit, page * limit + limit);
};

const AllEventList = () => {
  const [allEvents, setAllEvents] = useState<IFairAllEvents[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'upcoming',
  });
  const status = searchParams.get('status');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        if (searchValue) {
          const { data } = await axios.get(
            `${url}/api/fair/event/get/${searchValue}/${status}`
          );
          if (data.success) {
            setAllEvents(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          const { data } = await axios.get(
            `${url}/api/fair/event/get/all/${status}`
          );
          if (data.success) {
            setAllEvents(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status, searchValue]);
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handleLimitChange = (event: any): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginateAllEvents = applyPagination(
    allEvents.sort((a, b) => b.event_id - a.event_id),
    page,
    limit
  );
  return (
    <div>
      <Container maxWidth='xl' sx={{ mt: 4 }}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Box display='flex' justifyContent='space-between' gap={2}>
                    <TextField
                      id='outlined-basic'
                      label='Search by event name'
                      variant='outlined'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchValue(e.target.value)
                      }
                    />
                    <FormControl sx={{ width: '150px' }} variant='outlined'>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={status}
                        onChange={(e) =>
                          setSearchParams(`status=${e.target.value}`)
                        }
                        label='Status'
                        autoWidth
                      >
                        <MenuItem value='upcoming'>Upcoming</MenuItem>
                        <MenuItem value='ended'>Ended</MenuItem>
                        <MenuItem value='rejected'>Rejected</MenuItem>
                      </Select>
                    </FormControl>
                    <Button onClick={() => handleOpen()} variant='contained'>
                      Create Event
                    </Button>
                  </Box>
                }
                title='All Events'
              />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Venue</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>End Time</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableLoader />
                  ) : paginateAllEvents.length ? (
                    <TableBody>
                      {allEvents.map((sEvent) => (
                        <EventTableRow key={sEvent.event_id} sEvent={sEvent} />
                      ))}
                    </TableBody>
                  ) : (
                    <TableNotFound />
                  )}
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component='div'
                  count={allEvents.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
              {/* Modal */}
              <ModalsWrapper
                showModal={open}
                modalData={{ title: 'Create Event' }}
                setShowModal={setOpen}
              >
                <CreateEvent
                  handleClose={handleClose}
                  setAllEvents={setAllEvents}
                />
              </ModalsWrapper>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AllEventList;
