import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';

interface IProps {
  id: number;
  details: any;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const UpdatePaymentType = ({ id, details, setReload }: IProps) => {
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('');
  const Toast = Toaster();
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStatus(e.target.value);
    }
  };
  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (details.status && status && user.user_admin_id) {
      try {
        const obj = {
          phone: details.phone,
          mfs_type: details.mfs_type,
          status: status,
        };
        console.log(obj);
        setLoading(true);
        const { data } = await axios.patch(
          `${url}/api/fair/event/payment-method/${details.id}`,
          obj
        );
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Updated Successfully`,
          });
          setLoading(false);
          setReload((prev: Boolean) => !prev);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='MFS Type'
                  required
                  id='mfs_type'
                  type='text'
                  defaultValue={details?.mfs_type}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='phone'
                  label='Phone'
                  type='number'
                  defaultValue={details?.phone}
                />
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      status
                    </InputLabel>
                    <Select
                      id='outlined-required'
                      value={status}
                      label='Status'
                      onChange={handleStatusChange}
                      defaultValue={details?.status}
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={2}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update
              </Button>
            ) : (
              <LoadingButton
                loading
                fullWidth
                loadingIndicator='Updating...'
                variant='outlined'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Updating...
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default UpdatePaymentType;
