import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import Status404 from 'src/content/pages/Status/Status404';

const PermissionRoute = ({ component, route }) => {
  const { user } = useAuthContext();

  if (user.permissions.subModules.includes(route)) {
    return component;
  } else {
    return <Status404 />;
  }
};

export default PermissionRoute;
