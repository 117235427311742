import { LoadingButton } from '@mui/lab';
import {
  Button,
  CardContent,
  Grid,
  MenuItem,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { visitorSingleInvoiceInfo } from 'src/Utils/type';

type IProps = {
  singleInvoiceDetails: visitorSingleInvoiceInfo;
  handleEditModeChange: Function;
};

function InvoiceUpdateForm({
  singleInvoiceDetails,
  handleEditModeChange,
}: IProps) {
  const { register, handleSubmit, reset } = useForm();
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [getPaymentMethod, setGetPaymentMethod] = useState(null);
  const { user } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const Toast = Toaster();
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(new Date(event.target.value));
  };
  const currencies = [
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'paid',
      label: 'Paid',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
    },
    {
      value: 'reported',
      label: 'Reported',
    },
  ];

  const paymentMethod = [
    {
      value: 'Cash',
      label: 'Cash',
    },
    {
      value: 'SSL Commerz',
      label: 'SSL Commerz',
    },
    {
      value: 'Instrument',
      label: 'Instrument',
    },
    {
      value: 'Pay Order',
      label: 'Pay Order',
    },
  ];

  const onSubmit = async (inputs: any) => {
    if (singleInvoiceDetails.visitor_payment_invoice_id && user.user_admin_id) {
      try {
        setLoading(true);
        const bodyFormData = new FormData();

        // bodyFormData.append(
        //   'visitor_payment_invoice_transaction_id',
        //   singleInvoiceDetails.visitor_payment_invoice_transaction_id
        // );
        bodyFormData.append(
          'visitor_payment_invoice_updated_by',
          user.user_admin_id.toString()
        );
        // bodyFormData.append(
        //   'visitor_payment_invoice_payment_method',
        //   getPaymentMethod
        // );
        bodyFormData.append('visitor_payment_invoice_invoice_file', uploadFile);
        // bodyFormData.append(
        //   'visitor_payment_invoice_transaction_date',
        //   selectedDate.toISOString().slice(0, 10)
        // );
        for (const key in inputs) {
          if (inputs[key] != '') {
            bodyFormData.append(key, inputs[key]);
          }
        }
        const { data } = await axios.put(
          `${url}/api/fair/visitor/for-admin/visitor-invoice-update/${singleInvoiceDetails.visitor_payment_invoice_id}`,
          bodyFormData
        );
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Visitor Invoice Update Successfully`,
          });
          setSuccess(true);
          reset();
          setLoading(false);
        }
      } catch (err: any) {
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    <Container maxWidth='md'>
      <CardContent>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                select
                id='outlined-required'
                label='Select Payment Method'
                defaultValue={
                  singleInvoiceDetails?.visitor_payment_invoice_payment_method &&
                  singleInvoiceDetails?.visitor_payment_invoice_payment_method.toLowerCase()
                }
                {...register('visitor_payment_invoice_payment_method')}
              >
                {paymentMethod.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value.toLowerCase()}
                  >
                    {option.label.toLowerCase()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                style={{ width: '100%' }}
                id='filled-select-currency'
                select
                size='medium'
                label='Select status'
                defaultValue={singleInvoiceDetails?.visitor_payment_invoice_status.toLowerCase()}
                {...register('visitor_payment_invoice_status')}
                variant='outlined'
              >
                {currencies.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value.toLowerCase()}
                  >
                    {option.label.toLowerCase()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label='Date'
                fullWidth
                type='date'
                onChange={handleDateChange}
                defaultValue={getCurrentDate()} // Set the default date to today
                InputLabelProps={{ shrink: true }}
                {...register('visitor_payment_invoice_transaction_date')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id='outlined-required'
                type='file'
                label='Payment Invoice File'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => setUploadFile(e.target.files[0])}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                placeholder='Treansaction ID'
                minRows={2}
                maxRows={2}
                defaultValue={
                  singleInvoiceDetails?.visitor_payment_invoice_transaction_id
                }
                id='outlined-required'
                {...register('visitor_payment_invoice_transaction_id')}
                style={{ width: '100%' }}
              />
              {/* <TextareaAutosize
                placeholder='Treansaction ID'
                minRows={2}
                maxRows={2}
                defaultValue={
                  singleInvoiceDetails?.visitor_payment_invoice_transaction_id
                }
                id='outlined-required'
                {...register('visitor_payment_invoice_transaction_id')}
                style={{ width: '100%', padding: '15px' }}
              /> */}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                placeholder='Invoice remark'
                minRows={2}
                maxRows={5}
                defaultValue={
                  singleInvoiceDetails.visitor_payment_invoice_remark &&
                  singleInvoiceDetails?.visitor_payment_invoice_remark
                }
                id='outlined-required'
                {...register('visitor_payment_invoice_remark')}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          {!isLoading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
                '@media (max-width:600px)': {
                  width: '100%',
                },
              }}
            >
              Update visitor invoice
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Updating...'
              fullWidth
              variant='outlined'
              disabled
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
                '@media (max-width:600px)': {
                  width: '100%',
                },
              }}
            >
              <span>Updating...</span>
            </LoadingButton>
          )}
        </Box>
      </CardContent>
    </Container>
  );
}

export default InvoiceUpdateForm;
