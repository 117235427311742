import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, NavLink } from "react-router-dom";
import { IRoleList } from "src/Types/module/roleAndPermission/roleAndPermissionType";
import { url } from "src/Helpers/utils/Constant";

interface IProps {
  sRole: IRoleList;
}

const RoleTableRow = ({ sRole }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {sRole.roles_id}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {sRole.roles_name}
          </Typography>
        </TableCell>

        <TableCell>
          <Link to={`/admin-list/role/${sRole.roles_id}`}>
            <Tooltip title='view' arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RoleTableRow;
