import { Card, Container, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { url } from 'src/Helpers/utils/Constant';
import StallApplicationTable from './StallApplicationTable';
import { IStallFairApplication } from 'src/Utils/type';

const StallApplication = () => {
  const [fairAllMember, setFairAllMember] = useState<IStallFairApplication[]>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/fair/stall/get/stall-application/all/all`)
          .then(function (res) {
            if (res.data.success) {
              setFairAllMember(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setFairAllMember([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [reload]);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Helmet>
            <title>ATF - stall application list</title>
          </Helmet>
          <Card>
            <StallApplicationTable
              loading={loading}
              setReload={setReload}
              fairAllMember={fairAllMember}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StallApplication;
