import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { getStatusLabel } from 'src/Utils/Combined';
import { IProductByExhibitor } from 'src/Utils/type';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useParams } from 'react-router-dom';

type IProps = {
  productByExhibitor: IProductByExhibitor[];
};

function ProductByExhibitor({ productByExhibitor }: IProps) {
  const theme = useTheme();
  const { singleMemberFairId } = useParams();
  return (
    <TableContainer sx={{ marginBottom: 10 }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
          <TableRow>
            <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
              Product ID
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Product name
            </TableCell>
            {/* <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Price
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Special price
            </TableCell> */}
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Description
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Company name
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Category
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Status
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productByExhibitor.map((details: IProductByExhibitor) => (
            <TableRow hover key={details.fair_member_product_id}>
              <TableCell align='center'>
                {details.fair_member_product_id}
              </TableCell>
              <TableCell align='center'>
                {details.fair_member_product_name}
              </TableCell>
              {/* <TableCell align='center'>
                {details.fair_member_product_price}
              </TableCell>
              <TableCell align='center'>
                {details.fair_member_special_product_price}
              </TableCell> */}
              <TableCell align='center'>
                {details.fair_member_product_description}
              </TableCell>
              <TableCell align='center'>
                {details?.user_fair_member_company_name}
              </TableCell>
              <TableCell align='center'>
                {details?.fair_member_product_category
                  ? details?.fair_member_product_category
                  : 'N/A'}
              </TableCell>
              <TableCell align='center'>
                {getStatusLabel(details.user_fair_member_product_status)}
              </TableCell>
              <TableCell align='center'>
                <Link
                  to={`/exhibitor/list/${singleMemberFairId}/product/${details.fair_member_product_id.toString()}`}
                >
                  <Tooltip title='view' arrow>
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter,
                        },
                        color: theme.palette.primary.main,
                      }}
                      color='inherit'
                      size='small'
                    >
                      <VisibilityIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProductByExhibitor;
