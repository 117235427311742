import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Container, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";

interface Permission {
  permissions_id: number;
  permissions_name: string;
}

interface PermissionSection {
  permissions_section: string;
  permissions: Permission[];
}

interface Props {
  myArray: PermissionSection[];
  selectedPermissions: number[];
  setSelectedPermissions: any;
}

function MultiSelectCheckbox({
  myArray,
  selectedPermissions,
  setSelectedPermissions,
}: Props) {
  const handlePermissionChange = (permissionId: number) => {
    if (selectedPermissions.includes(permissionId)) {
      setSelectedPermissions(
        selectedPermissions.filter((id) => id !== permissionId)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    }
  };

  const handleSectionChange = (sectionName: string) => {
    const permissionsInSection = myArray.find(
      (section) => section.permissions_section === sectionName
    )?.permissions;
    if (!permissionsInSection) {
      return;
    }
    const permissionIds = permissionsInSection.map(
      (permission) => permission.permissions_id
    );
    if (selectedPermissions.some((id) => permissionIds.includes(id))) {
      setSelectedPermissions(
        selectedPermissions.filter((id) => !permissionIds.includes(id))
      );
    } else {
      setSelectedPermissions([...selectedPermissions, ...permissionIds]);
    }
  };

  const isAllChecked = (sectionName: string) => {
    const permissionsInSection = myArray.find(
      (section) => section.permissions_section === sectionName
    )?.permissions;
    if (!permissionsInSection) {
      return false;
    }
    const permissionIds = permissionsInSection.map(
      (permission) => permission.permissions_id
    );
    return permissionIds.every((id) => selectedPermissions.includes(id));
  };

  return (
    <>
      {myArray.map((section) => (
        <Container key={section.permissions_section}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              bgcolor: "#ededed",
              borderRadius: "5px",
            }}
          >
            <Typography variant='h4' sx={{ mt: 2, mb: 1 }}>
              {section.permissions_section
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Typography>
            <FormGroup>
              <Checkbox
                color='primary'
                checked={isAllChecked(section.permissions_section)}
                onChange={() =>
                  handleSectionChange(section.permissions_section)
                }
              />
            </FormGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              ml: 2,
            }}
          >
            <FormGroup>
              {section.permissions.map((permission) => (
                <FormControlLabel
                  key={permission.permissions_id}
                  label={permission.permissions_name
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  control={<Checkbox />}
                  color='primary'
                  checked={selectedPermissions.includes(
                    permission.permissions_id
                  )}
                  onChange={() =>
                    handlePermissionChange(permission.permissions_id)
                  }
                />
              ))}
            </FormGroup>
          </Box>
        </Container>
      ))}
    </>
  );
}

export default MultiSelectCheckbox;
