import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { getStatusLabel } from 'src/Utils/Combined';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { IReference } from './refereceTypes';

type IProps = {
  reference: IReference;
  setAllReference: any;
  allReference: any;
};

const ReferenceTableRow = ({
  reference,
  allReference,
  setAllReference,
}: IProps) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { user } = useAuthContext();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {reference?.id}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {reference?.organization}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {reference?.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {reference?.limit}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {getStatusLabel(reference?.status === 1 ? 'Active' : 'Inactive')}
          </Typography>
        </TableCell>

        <TableCell>
          <Box>
            <Link to={`/reference/reference-list/details/${reference?.id}`}>
              <Tooltip title='view' arrow>
                <IconButton
                  sx={{
                    '&:hover': {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color='inherit'
                  size='small'
                >
                  <VisibilityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Link>

            {/* Delele Confirmation modal  */}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReferenceTableRow;
