import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { IAuditAndTrail } from 'src/Types/module/auditAndTrail/auditAndTrail';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import AuditAndTrailTableRow from './AuditAndTrailTableRow';
import { DatePicker } from '@mui/lab';
import { Iadmin } from 'src/Types/module/admin/adminTypes';

const AuditAndTrail = () => {
  const [limit, setLimit] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [allAuditTrail, setAllAuditTrail] = useState<IAuditAndTrail[]>([]);
  const [allAdmin, setAllAdmin] = useState<Iadmin[]>([]);
  const [adminId, setAdminId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);

  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });

  const page = parseInt(searchParams.get('page'));
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/atab/audit-trail/all?limit=${limit}&skip=${
          limit * page
        }`;
        if (startDate && endDate) {
          uri = `${url}/api/atab/audit-trail/all?from_date=${
            startDate.toISOString().split('T')[0]
          }&&to_date=${
            endDate.toISOString().split('T')[0]
          }&limit=${limit}&skip=${limit * page}`;
        }
        if (adminId) {
          uri = `${url}/api/atab/audit-trail/${adminId}?limit=${limit}&skip=${
            limit * page
          }`;
        }
        if (startDate && endDate && adminId) {
          uri = `${url}/api/atab/audit-trail/${adminId}?from_date=${
            startDate.toISOString().split('T')[0]
          }&&to_date=${
            endDate.toISOString().split('T')[0]
          }&limit=${limit}&skip=${limit * page}`;
        }

        axios
          .get(uri)
          .then(function (res) {
            if (res.data.success) {
              setAllAuditTrail(res.data.data);
              setLoading(false);
              setTotal(res.data.total);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAuditTrail([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [startDate, endDate, adminId, page, limit]);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/all/status/all`)
          .then(function (res) {
            if (res.data.success) {
              setAllAdmin(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmin([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={'bold'}
                      variant='h6'
                      component='h6'
                      gutterBottom
                    >
                      From
                    </Typography>
                    <DatePicker
                      label='Start Date'
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '100%' }} />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={'bold'}
                      variant='h6'
                      component='h6'
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label='End Date'
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '100%' }} />
                      )}
                    />
                  </Grid>
                  {/* <Grid>
                    <FormControl sx={{ width: '250px' }} variant='outlined'>
                      <InputLabel>Admin</InputLabel>
                      <Select
                        value={adminId}
                        onChange={(e) => setAdminId(e.target.value)}
                        label='Status'
                        autoWidth
                      >
                        {allAdmin &&
                          allAdmin.map((sAdmin) => (
                            <MenuItem
                              key={sAdmin.user_admin_id}
                              value={sAdmin.user_admin_id}
                            >
                              {sAdmin.user_admin_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Box>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>IMAGE</TableCell>
                    <TableCell>Admin Name</TableCell>
                    <TableCell>Details</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : allAuditTrail.length ? (
                    <>
                      {allAuditTrail.map((sAudit: IAuditAndTrail) => {
                        return (
                          <AuditAndTrailTableRow
                            sAudit={sAudit}
                            key={sAudit.audit_trail_id}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AuditAndTrail;
