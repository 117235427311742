import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';
import { invoiceByFairMember } from 'src/Utils/type';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ISingleVisitorProduct } from './types/singleVisitortype';
import { imgUrl } from 'src/Helpers/utils/Constant';

function SingleVisitorProduct({ visitorProduct }) {
  const theme = useTheme();
  // Sort the products by ID in ascending order
  const sortedProducts = React.useMemo(() => {
    return [...visitorProduct].sort(
      (a, b) => a.fair_member_product_id - b.fair_member_product_id
    );
  }, [visitorProduct]);

  return (
    <TableContainer sx={{ marginBottom: 10 }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
          <TableRow>
            <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
              Product ID
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Product Name
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Product Image
            </TableCell>
            {/* <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Product Price
            </TableCell> */}
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Product Type
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Company Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedProducts.length > 0 ? (
            sortedProducts.map((details: ISingleVisitorProduct) => (
              <TableRow hover key={details.fair_member_product_id}>
                <TableCell align='center'>
                  {details.fair_member_product_id}
                </TableCell>
                <TableCell align='center'>
                  {details.fair_member_product_name}
                </TableCell>
                <TableCell align='center'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {details?.fair_member_product_photo?.map((photo, index) => (
                      <Avatar
                        style={{ marginRight: '1.5rem' }}
                        src={`${imgUrl}/fair_files/${photo.fair_member_product_photo}`}
                      />
                    ))}
                  </div>
                </TableCell>

                {/* <TableCell align='center'>
                  {details.fair_member_product_price}
                </TableCell> */}
                <TableCell align='center'>
                  {getStatusLabel(details.user_fair_member_product_type)}
                </TableCell>
                <TableCell align='center'>
                  {details.user_fair_member_company_name}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <h2>Not Found</h2>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SingleVisitorProduct;
