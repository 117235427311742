import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from '@mui/material';

import VisitorInvoiceTableRow from './VisitorInvoiceTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IVisitorInvoiceList } from 'src/Utils/type';

const applyPagination = (
  allVisitorInvoice: IVisitorInvoiceList[],
  page: number,
  limit: number
): IVisitorInvoiceList[] => {
  return allVisitorInvoice.slice(page * limit, page * limit + limit);
};

const VisitorInvoiceTable = ({
  allVisitorInvoice,
  loading,
  setStatus,
  status,
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const statusOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'paid',
      name: 'Paid',
    },
    {
      id: 'cancelled',
      name: 'Cancelled',
    },
    {
      id: 'reported',
      name: 'Reported',
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allVisitorInvoice, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '320px',
            }}
          >
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label='Status'
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title='Visitor invoice List'
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Invoice issue date</TableCell>
              <TableCell>Visitor ID</TableCell>
              <TableCell>Visitor name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : paginatedInvoice.length ? (
            <TableBody>
              {paginatedInvoice.map((visitorInvoice: IVisitorInvoiceList) => {
                return (
                  <VisitorInvoiceTableRow
                    key={visitorInvoice.visitor_payment_invoice_id}
                    visitorInvoice={visitorInvoice}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={allVisitorInvoice.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default VisitorInvoiceTable;
