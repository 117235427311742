import React, { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Grid,
  TextField,
  Chip,
  Autocomplete,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

interface IMemberProps {
  user_fair_member_id: number;
  user_fair_member_contact_name: string;
}
interface IProps {
  handleClose: Function;
  setExhibitorInvoice: Function;
}

const CreateExhibitorInvoice = ({
  handleClose,
  setExhibitorInvoice,
}: IProps) => {
  const [userMember, setUserMember] = useState<IMemberProps[]>([]);
  const [isFetchLoading, setFetchLoading] = useState<Boolean>(false);
  const [isMgsSent, setIsMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IMemberProps | null>(null);
  const [totalAmount, setTotalAmount] = useState<string>('');
  const Toast = Toaster();

  const { user } = useAuthContext();
  useEffect(() => {
    setFetchLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/fair/member/get/all/all`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setUserMember(data.data.data);
        setFetchLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setFetchLoading(false);
        } else {
          // handle error
          setFetchLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsMsgSent(true);
    if (!membersId) {
      Toast.fire({
        icon: 'error',
        title: `Select a member`,
      });
    } else {
      const body = {
        fair_member_id: membersId.user_fair_member_id,
        total_amount: totalAmount,
      };
      try {
        const { data } = await axios.post(
          `${url}/api/atab/fair-invoice/create/for-admin?user_admin_id=${user.user_admin_id}`,
          body
        );

        if (data.success) {
          setExhibitorInvoice((prev) => [
            {
              fair_invoice_id: data?.id,
              invoice_issue_date: new Date().toString(),
              total_amount: totalAmount,
              fair_invoice_transaction_date: null,
              fair_invoice_transaction_id: null,
              fair_invoice_payment_method: '',
              fair_invoice_remark: null,
              fair_invoice_status: 'pending',
              user_fair_member_id: membersId.user_fair_member_id,
              user_fair_member_contact_name:
                membersId.user_fair_member_contact_name,
            },
            ...prev,
          ]);
          Toast.fire({
            icon: 'success',
            title: `Invoice Successfully created`,
          });
          setIsMsgSent(false);
          setTotalAmount('');
          setMembersId(null);
          handleClose();
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `Something went to wrong`,
        });
        setIsMsgSent(false);
      }
    }
  };

  return (
    <>
      {!isFetchLoading ? (
        <>
          <Helmet>
            <title>ATF - Create a exhibitor invoice</title>
          </Helmet>
          <Box component='form' onSubmit={handleSubmit} sx={{ p: 2 }}>
            <Grid container spacing={{ xs: 2 }} mb={2}>
              <Grid item lg={12}>
                <Autocomplete
                  placeholder='Select a member'
                  value={membersId}
                  onChange={(event: any, newValue: any) => {
                    setMembersId(newValue);
                  }}
                  id='controllable-states-demo'
                  options={userMember}
                  getOptionLabel={(option) =>
                    option.user_fair_member_contact_name
                  }
                  renderTags={(value: readonly any[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        key={index}
                        variant='filled'
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label='Select member' />
                  )}
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Total amount'
                  required
                  id='outlined-required'
                  value={totalAmount}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setTotalAmount(e.target.value)
                  }
                  type='number'
                />
              </Grid>
              <Grid item lg={12}>
                {!isMgsSent ? (
                  <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    disableElevation
                  >
                    Create exhibitor invoice
                  </Button>
                ) : (
                  <LoadingButton
                    loading
                    loadingIndicator='Creating...'
                    fullWidth
                    variant='contained'
                    disabled
                  >
                    <span>Creating...</span>
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <GlobalLoader />
      )}
    </>
  );
};

export default CreateExhibitorInvoice;
