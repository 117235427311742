import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SuspenseLoader from 'src/components/SuspenseLoader';
import SingleVisitorProduct from './SingleVisitorProduct';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
type IProps = {
  visitorProduct: any[];
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
  loading: Boolean;
};

const ProductByVisitor = ({
  visitorProduct,
  setValue,
  value,
  loading,
}: IProps) => {
  const notFound = (
    <Typography variant='h2' textAlign='center'>
      Not found
    </Typography>
  );

  let content: ReactNode;
  if (loading) {
    content = <SuspenseLoader />;
  } else if (value === 'product' && !visitorProduct.length) {
    content = notFound;
  } else if (value === 'product' && visitorProduct.length > 0) {
    content = <SingleVisitorProduct visitorProduct={visitorProduct} />;
  }
  return (
    <Container maxWidth='lg'>
      <>
        <Box sx={{ py: 3 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              value='product'
              label='WishList'
              icon={<AutoAwesomeIcon />}
            />
          </BottomNavigation>
        </Box>
        <Box sx={{ position: 'relative', minHeight: '250px' }}>{content}</Box>
      </>
    </Container>
  );
};

export default ProductByVisitor;
