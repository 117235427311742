import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import EntryFairTable from './EntryFairTable';
import { exhibitorInvoice } from 'src/Utils/type';

const EntryFairList = () => {
  const [entryFairList, setEntryFairList] = useState<exhibitorInvoice[] | null>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [fromDate, setFromDate] = useState<string>(''); // State for start date
  const [toDate, setToDate] = useState<string>(''); // State for end date
  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    //   axios
    //     .get(`${url}/api/fair/entry/get/all`, {
    //       cancelToken: source.token,
    //     })
    //     .then((data) => {
    //       setEntryFairList(data.data.data);
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       if (axios.isCancel(err)) {
    //         setLoading(false);
    //       } else {
    //         // handle error
    //         setLoading(false);
    //       }
    //     });
    //   return () => {
    //     // cancel the request before component unmounts
    //     source.cancel();
    //   };
    // }, []);

    let apiUrl = `${url}/api/fair/entry/get/all`;

    // Append date range parameters if both start and end dates are selected
    if (fromDate && toDate) {
      apiUrl += `?from_date=${fromDate}&to_date=${toDate}`;
    }

    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((data) => {
        setEntryFairList(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });

    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [fromDate, toDate]); // Listen for changes in fromDate and toDate

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                    alignItems: 'start',
                  }}
                >
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='by_date'
                    label='Select From Date'
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='by_date'
                    label='Select To Date'
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Box>
              }
              title='Entry fair List'
            />
            <EntryFairTable loading={loading} entryFairList={entryFairList} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EntryFairList;
