import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { url } from 'src/Helpers/utils/Constant';
import { LoadingButton } from '@mui/lab';
import Toaster from 'src/Toaster/Toaster';

const CreateReference = ({ allReference, handleClose, setAllReference }) => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();

  const [loading, setLoading] = useState<Boolean>(false);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    if (user.user_admin_id) {
      try {
        const { organization, name, designation, limit } = inputs;
        const { data } = await axios.post(
          `${url}/api/fair/event/for-admin/reference`,
          {
            organization,
            name,
            designation,
            limit,
          }
        );

        if (data.success) {
          setLoading(false);
          setAllReference((prev) => [
            ...prev,
            {
              id: data.id,
              organization: organization,
              name: name,
              designation: designation,
              limit: limit,
              status: 1,
            },
          ]);
          Toast.fire({
            icon: 'success',
            title: `Successfully created`,
          });
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    } else {
      setLoading(false);
      Toast.fire({
        icon: 'error',
        title: 'Field required',
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>ATF - Create Reference</title>
      </Helmet>

      <Box sx={{ p: 2 }} className=''>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin='normal'
                fullWidth
                label='Organization'
                required
                id='organization'
                {...register('organization')}
                type='text'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='name'
                label='Dean Name'
                {...register('name')}
                type='text'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='designation'
                label='Designation'
                {...register('designation')}
                type='text'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='limit'
                label='Limit'
                {...register('limit')}
                type='number'
              />
            </Grid>
          </Grid>

          {!loading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Reference
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Creating...
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateReference;
