import { TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { getStatusLabelFairStall } from "src/Utils/Combined";
import moment from "moment";
import { IFairStall } from "src/Types/module/fairStall/fairStallType";

type IProps = {
  allAdmin: IFairStall;
};

const FairStallTableRow = ({ allAdmin }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover key={allAdmin.fair_stall_id}>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_stall_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_stall_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_stall_type}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_stall_hall_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.fair_stall_created_at).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabelFairStall(allAdmin.fair_stall_available_status)}
        </TableCell>
      </TableRow>
    </>
  );
};

export default FairStallTableRow;
