import { ChangeEvent, useEffect, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { IRaffleDrawAll } from './raffledrawTypes';
import DrawTableRow from './DrawTableRow';
import CreateDraw from './CreateDraw';
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/lab';

const AllDrawlist = () => {
  const [allPayment, setAllPayment] = useState([]);
  const [loading, setLoading] = useState<boolean>(true); // Initially set to true
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [searchParams, setSearchParams] = useSearchParams({
    by_date: '', // Set an empty string as the default date
  });
  const by_date = searchParams.get('by_date');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // State for selected date as a string
  const [selectedDate, setSelectedDate] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        // Encode the selectedDate before appending it to the URL
        const encodedDate = encodeURIComponent(selectedDate);
        const { data } = await axios.get(
          `${url}/api/fair/raffle-draw?by_date=${encodedDate}`
        );
        if (data.success) {
          setAllPayment(data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when the data is loaded or there's an error
      }
    })();
  }, [selectedDate]);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allPayment, page, limit);

  return (
    <Box sx={{ m: 2 }}>
      <Card>
        <CardHeader
          action={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                alignItems: 'start',
              }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='by_date'
                label='Select Date'
                type='date'
                InputLabelProps={{ shrink: true }}
                value={selectedDate}
                onChange={(e: any) => setSelectedDate(e.target.value)} // Store the selected date as a string
              />
              <div>
                <Button
                  style={{ width: '200px', marginTop: '20px' }}
                  onClick={() => handleOpen()}
                  variant='contained'
                >
                  Create Raffle Draw
                </Button>
              </div>
            </Box>
          }
          title='Raffle Draw List'
        />

        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Position</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Visitor Name</TableCell>
                <TableCell>Visitor ID</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableLoader />
            ) : allPayment.length ? (
              <TableBody>
                {paginatedInvoice.map((singleDraw: IRaffleDrawAll) => {
                  return (
                    <DrawTableRow
                      key={singleDraw.visitor_id}
                      payment={singleDraw}
                      setAllPayment={setAllPayment}
                      allPayment={allPayment}
                    />
                  );
                })}
              </TableBody>
            ) : (
              <TableNotFound />
            )}
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component='div'
            count={allPayment.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
        {/* Modal */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          maxWidth='md'
          onClose={handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ mb: 2 }}>Create Raffle Draw</Typography>
            </Box>
            <CreateDraw
              handleClose={handleClose}
              allPayment={allPayment}
              setAllPayment={setAllPayment}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
};

export default AllDrawlist;
