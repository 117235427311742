import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { getStatusLabel } from 'src/Utils/Combined';
import { IGuest } from 'src/Types/module/guestAndSpecker/guestAndSpeaker';
import moment from 'moment-timezone';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import UpdateGuestAndSpeaker from './UpdateGuestAndSpeaker';
import UpdateSpeakerMaterials from './UpdateSpeakerMaterials';

interface IProps {
  guest: IGuest;
}
const SingleGuestAndSpeakerDetails = ({ guest }: IProps) => {
  const isGuestSpeaker = guest?.guest_speaker_type === 'guest';
  const [showModal, setShowModal] = useState<boolean>(false);
  const [materials, setMaterial] = useState<any>();
  const [modalData, setModalData] = useState<any>();

  return (
    <div>
      <div>
        <Box sx={{ mt: 3 }}>
          <Grid container>
            <Grid item lg={4} sm={12} md={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  width={200}
                  height={250}
                  style={{
                    borderRadius: '5px',
                  }}
                  src={`${imgUrl}/guest-speaker-files/${guest?.guest_speaker_photo}`}
                  alt=''
                />
              </Box>
            </Grid>
            <Grid item lg={8} sm={4} md={4}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    ID:
                  </Typography>
                  {guest?.guest_speaker_id}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Name:
                  </Typography>
                  {guest?.guest_speaker_name}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Company Name:
                  </Typography>
                  {guest?.guest_speaker_company_name}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Designation:
                  </Typography>
                  {guest?.guest_speaker_designation}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Type:
                  </Typography>
                  {guest?.guest_speaker_type}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Status:
                  </Typography>
                  {getStatusLabel(guest?.guest_speaker_status)}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Created At:
                  </Typography>
                  {moment
                    .tz(guest?.guest_speaker_created_at, 'Asia/Dhaka')
                    .format('LT')}
                  <span> | </span>
                  {moment
                    .tz(guest?.guest_speaker_created_at, 'Asia/Dhaka')
                    .format('MMM Do YY')}
                </Grid>

                <Grid item lg={12} sm={12} md={12}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Details:
                  </Typography>
                  {guest?.guest_speaker_details}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        {!isGuestSpeaker && (
          <div>
            <Box sx={{ mt: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                <Typography
                  style={{ marginBottom: '10px' }}
                  variant='h4'
                  color='text.primary'
                >
                  Materials
                </Typography>
              </Box>
              <Divider />
              <TableContainer>
                <Table style={{ marginTop: '20px' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Details</TableCell>
                      <TableCell>Material File</TableCell>
                      <TableCell>Visibility</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guest?.speakerMaterials.map((material) => (
                      <TableRow>
                        <TableCell>{material?.sm_details}</TableCell>
                        <Typography
                          fontWeight='bold'
                          display='inline'
                          sx={{ pr: 1 }}
                        >
                          <a
                            href={`${imgUrl}/guest_speaker_material_files/${material.sm_file}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            Download File
                          </a>
                        </Typography>
                        <TableCell>
                          {getStatusLabel(
                            material?.sm_visiblity === 1
                              ? 'Published'
                              : 'Unpublished'
                          )}
                          {}
                        </TableCell>

                        <TableCell>
                          <Tooltip title='Edit'>
                            <IconButton
                              onClick={() => {
                                setShowModal(!showModal);
                                setModalData(material);
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ModalsWrapper
                showModal={showModal}
                setShowModal={setShowModal}
                modalData={{ title: 'Update Speaker Materials' }}
              >
                <UpdateSpeakerMaterials
                  modalData={modalData}
                  materials={materials}
                  setMaterials={setMaterial}
                  setShowModal={setShowModal}
                />
              </ModalsWrapper>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleGuestAndSpeakerDetails;
