import { MODAL_HIDE, MODAL_SHOW } from 'src/Helpers/utils/Constant';

export const reducer = (state, action) => {
  switch (action.type) {
    case MODAL_SHOW:
      return {
        ...state,
        modalData: action.payload,
        showModal: true,
      };

    case MODAL_HIDE:
      return { ...state, showModal: false };
    default:
      throw new Error(`No matching action type - ${action.type}`);
  }
};
