import { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { IPermissionList } from "src/Types/module/roleAndPermission/roleAndPermissionType";

interface IProps {
  permission: IPermissionList;
  isChecked: boolean;
  handlePermissionChange: any;
}
const SingleCheckedBox = ({
  permission,
  isChecked,
  handlePermissionChange,
}: IProps) => {
  const [checked, setchecked] = useState(isChecked);

  return (
    <FormControlLabel
      label={permission.permissions_name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")}
      control={<Checkbox />}
      color='primary'
      checked={checked}
      onClick={() => setchecked(!checked)}
      onChange={(e) => handlePermissionChange(e, permission.permissions_id)}
    />
  );
};

export default SingleCheckedBox;
