import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  invoiceByFairMember,
  IProductByExhibitor,
  IVisitorByExhibitor,
} from 'src/Utils/type';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SingleExhibitorInvoice from './SingleExhibitorInvoice';
import VisitorByExhibitor from './VisitorByExhibitor';
import ProductByExhibitor from './ProductByExhibitor';
import SuspenseLoader from 'src/components/SuspenseLoader';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeadByExhibitor from './LeadByExibitor';
import { ILead } from './types/leadtype';
type IProps = {
  memberInvoice: invoiceByFairMember[];
  productByExhibitor: IProductByExhibitor[];
  visitorByExhibitor: IVisitorByExhibitor[];
  leadByExhibitor: ILead[];
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
  loading: Boolean;
};

const InvoiceByFairMember = ({
  memberInvoice,
  leadByExhibitor,
  visitorByExhibitor,
  productByExhibitor,
  setValue,
  value,
  loading,
}: IProps) => {
  const notFound = (
    <Typography variant='h2' textAlign='center'>
      Not found
    </Typography>
  );
  let content: ReactNode;
  if (loading) {
    content = <SuspenseLoader />;
  } else if (value === 'visitor' && !visitorByExhibitor.length) {
    content = notFound;
  } else if (value === 'invoice' && !memberInvoice.length) {
    content = notFound;
  } else if (value === 'product' && !productByExhibitor.length) {
    content = notFound;
  } else if (value === 'lead' && !leadByExhibitor.length) {
    content = notFound;
  } else if (value === 'invoice' && memberInvoice.length > 0) {
    content = <SingleExhibitorInvoice memberInvoice={memberInvoice} />;
  } else if (value === 'product' && productByExhibitor.length > 0) {
    content = <ProductByExhibitor productByExhibitor={productByExhibitor} />;
  } else if (value === 'visitor' && visitorByExhibitor.length > 0) {
    content = <VisitorByExhibitor visitorByExhibitor={visitorByExhibitor} />;
  } else if (value === 'lead' && leadByExhibitor.length > 0) {
    content = <LeadByExhibitor leadByExhibitor={leadByExhibitor} />;
  }
  return (
    <Container maxWidth='lg'>
      <>
        <Box sx={{ py: 3 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              value='invoice'
              label='Invoice'
              icon={<ReceiptTwoToneIcon />}
            />
            <BottomNavigationAction
              value='product'
              label='Product'
              icon={<InventoryTwoToneIcon />}
            />
            <BottomNavigationAction
              value='visitor'
              label='Visitor'
              icon={<PeopleAltTwoToneIcon />}
            />
            <BottomNavigationAction
              value='lead'
              label='Lead'
              icon={<LeaderboardIcon />}
            />
          </BottomNavigation>
        </Box>
        <Box sx={{ position: 'relative', minHeight: '250px' }}>{content}</Box>
      </>
    </Container>
  );
};

export default InvoiceByFairMember;
