import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { getStatusLabel } from 'src/Utils/Combined';
import { useState } from 'react';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { ISingleReference } from './refereceTypes';

interface IProps {
  reference: ISingleReference;
}
const SingleReferenceDetails = ({ reference }: IProps) => {
  console.log(reference);
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div>
      <div>
        <Box sx={{ mt: 3 }}>
          <Grid container>
            <Grid item lg={8} sm={4} md={4}>
              <Grid
                container
                spacing={{ xs: 2, md: 6 }}
                columns={{ xs: 4, sm: 8, md: 16 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    ID:
                  </Typography>
                  {reference?.id}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Organization:
                  </Typography>
                  {reference?.organization}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Name:
                  </Typography>
                  {reference?.name}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Designation:
                  </Typography>
                  {reference?.designation}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Limit:
                  </Typography>
                  {reference?.limit}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Reference ID:
                  </Typography>
                  {reference?.refId}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Status:
                  </Typography>
                  {getStatusLabel(
                    reference?.status === 1 ? 'Active' : 'Inactive'
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        <div>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Typography
                style={{ marginBottom: '10px' }}
                variant='h4'
                color='text.primary'
              >
                Visitors
              </Typography>
            </Box>
            <Divider />
            <TableContainer>
              <Table style={{ marginTop: '20px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Event ID</TableCell>
                    <TableCell>Event Title</TableCell>
                    <TableCell>Visitor ID</TableCell>

                    <TableCell>Visitor Name</TableCell>
                    <TableCell>Visitor Joined ID</TableCell>
                    <TableCell>Visitor Joined Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reference?.referenced_visitor.map((ref_vis) => (
                    <TableRow>
                      <TableCell>{ref_vis?.event_id}</TableCell>
                      <TableCell>{ref_vis?.event_title}</TableCell>
                      <TableCell>{ref_vis?.visitor_id}</TableCell>
                      <TableCell>{ref_vis?.visitor_first_name}</TableCell>
                      <TableCell>{ref_vis?.fair_event_joined_id}</TableCell>

                      <TableCell>
                        {getStatusLabel(
                          ref_vis?.fair_event_joined_visitor_status
                        )}
                        {}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SingleReferenceDetails;
