import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/components/Context/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthContextProvider } from './components/Context/AuthContext/AuthContext';
import { ModalContextProvider } from './components/Context/ModalContext';

ReactDOM.render(
  <HelmetProvider>
    <ModalContextProvider>
      <SidebarProvider>
        {/* <BrowserRouter> */}
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
        {/* </BrowserRouter> */}
      </SidebarProvider>
    </ModalContextProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
