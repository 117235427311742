import React, { ChangeEvent, useState } from 'react';
import { DatePicker } from '@mui/lab';
import {
  TextField,
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IFairMemberInvoice } from '../Types/AllReportTypes';
import FairMemberInvoiceTableRow from './FairMemberInvoiceRow';
import moment from 'moment';

const applyPagination = (
  entryFairMemberInvoiceList: IFairMemberInvoice[],
  page: number,
  limit: number
): IFairMemberInvoice[] => {
  return entryFairMemberInvoiceList.slice(page * limit, page * limit + limit);
};

const FairMemberInvoiceTable = ({ entryFairMemberInvoiceList, loading }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
  };

  const paginatedInvoice = applyPagination(
    entryFairMemberInvoiceList.filter(
      (invoice) =>
        (!fromDate ||
          moment(invoice.issue_date).isSameOrAfter(moment(fromDate), 'day')) &&
        (!toDate ||
          moment(invoice.issue_date).isSameOrBefore(moment(toDate), 'day'))
    ),
    page,
    limit
  );

  return (
    <Card>
      <CardHeader title='Fair Member Invoice List' />
      <Divider />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice ID</TableCell>
              <TableCell>Issue Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Transaction Date</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : paginatedInvoice.length ? (
              <>
                {paginatedInvoice.map(
                  (fairMemberInvoice: IFairMemberInvoice) => {
                    return (
                      <FairMemberInvoiceTableRow
                        key={fairMemberInvoice.fair_invoice_id}
                        fairMemberInvoice={fairMemberInvoice}
                      />
                    );
                  }
                )}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={entryFairMemberInvoiceList.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default FairMemberInvoiceTable;
