import { TableCell, TableRow } from '@mui/material';
import SuspenseLoader from 'src/components/SuspenseLoader';

export function TableLoader() {
  return (
    <TableRow>
      <TableCell align='center' colSpan={100}>
        <SuspenseLoader />
      </TableCell>
    </TableRow>
  );
}
export function TableNotFound() {
  return (
    <TableRow>
      <TableCell
        sx={{ fontSize: '24px', fontWeight: 'bold' }}
        colSpan={100}
        align='center'
      >
        No data found
      </TableCell>
    </TableRow>
  );
}
