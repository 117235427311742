import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { url } from 'src/Helpers/utils/Constant';
import { LoadingButton } from '@mui/lab';
import Toaster from 'src/Toaster/Toaster';
import { muiStyles } from 'src/Utils/Styles';

const useStyles = muiStyles;
const CreateDraw = ({ allPayment, handleClose, setAllPayment }) => {
  const { user } = useAuthContext();

  const Toast = Toaster();
  const classes = useStyles();
  const { register, handleSubmit, reset } = useForm();

  const [loading, setLoading] = useState<Boolean>(false);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    if (user.user_admin_id) {
      try {
        const { wining_date } = inputs;
        const { data } = await axios.post(`${url}/api/fair/raffle-draw`, {
          wining_date,
        });

        if (data.success) {
          setLoading(false);
          setAllPayment((prev) => [
            ...prev,
            {
              id: data.id,
              wining_date: wining_date,
            },
          ]);
          Toast.fire({
            icon: 'success',
            title: `Successfully created`,
          });
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    } else {
      setLoading(false);
      Toast.fire({
        icon: 'error',
        title: 'Field required',
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>ATF - Create Draw</title>
      </Helmet>

      <Box sx={{ p: 2 }} className=''>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='wining_date'
                label='Enter Date'
                InputLabelProps={{ shrink: true }}
                {...register('wining_date')}
                type='date'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
          </Grid>

          {!loading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Raffle Draw
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Creating...
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateDraw;
