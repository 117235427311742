import { ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Grid,
} from '@mui/material';
import StallApplicationTableRow from './StallApplicationTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { IStallFairApplication } from 'src/Utils/type';
import { useSearchParams } from 'react-router-dom';

const applyPagination = (
  fairAllMember: IStallFairApplication[],
  page: number,
  limit: number
): IStallFairApplication[] => {
  return fairAllMember.slice(page * limit, page * limit + limit);
};

const StallApplicationTable = ({ fairAllMember, loading, setReload }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'all',
  });
  const status = searchParams.get('status');

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(fairAllMember, page, limit);

  return (
    <div>
      <Container maxWidth='xl' sx={{ mt: 4 }}></Container>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          {' '}
          <Card>
            <CardHeader title='Stall Application List' />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Member name</TableCell>
                    <TableCell>Application ID</TableCell>
                    <TableCell>Member contact name</TableCell>
                    <TableCell>Application member ID</TableCell>
                    <TableCell>Start date</TableCell>
                    <TableCell>End date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableLoader />
                ) : paginatedInvoice.length ? (
                  <TableBody>
                    {paginatedInvoice.map((allAdmin: IStallFairApplication) => {
                      return (
                        <StallApplicationTableRow
                          key={allAdmin.fair_stall_application_id}
                          allAdmin={allAdmin}
                          setReload={setReload}
                        />
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableNotFound />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={fairAllMember.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default StallApplicationTable;
