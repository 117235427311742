import { Button } from '@mui/material';
import Excel from 'exceljs';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import axios from 'axios';
import GlobalLoader from '../SuspenseLoader/GlobalLoader';

const ExcelGenerator = ({ getDownloadData }: { getDownloadData: any }) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const data = await getDownloadData();

    console.log(data);
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet('member_list');

      const titleRow = worksheet.addRow([
        'Fair Entry ID',
        'Visitor ID',
        'Entry Time',
        'Visitor Name',
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      await Promise.all(
        data.map(async (item) => {
          const row = worksheet.addRow([
            item.fair_entry_id,
            item.visitor_id,
            item.entry_time,
            item.visitor_first_name,
          ]);
        })
      );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `list.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant='outlined'>
          Download excel
        </Button>
      )}
    </>
  );
};

export default ExcelGenerator;

// get image buffer
async function getImageBuffer(url: string) {
  try {
    const imgBuffer = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    return imgBuffer;
  } catch (err) {
    return null;
  }
}
