import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { singleVisitorInfo } from 'src/Utils/type';
import { useNavigate } from 'react-router';
import { imgUrl } from 'src/Helpers/utils/Constant';

interface IProps {
  visitorInfo: singleVisitorInfo;
}

const SingleVisitorInfo = ({ visitorInfo }: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography
            textAlign='center'
            gutterBottom
            variant='h2'
            component='div'
          >
            Single Visitor Information
          </Typography>

          <div>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item lg={4} sm={12} md={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      width={200}
                      height={250}
                      style={{
                        borderRadius: '5px',
                      }}
                      src={
                        visitorInfo?.visitor_photo
                          ? `${imgUrl}/visitor_files/${visitorInfo?.visitor_photo}`
                          : '/static/images/avatars/avatar2.png'
                      }
                      alt=''
                    />
                  </Box>
                </Grid>
                <Grid item lg={8} sm={4} md={4}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        ID:
                      </Typography>
                      {visitorInfo?.visitor_id}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Name:
                      </Typography>
                      {visitorInfo?.visitor_first_name}{' '}
                      {visitorInfo?.visitor_last_name}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Mobile:
                      </Typography>
                      {visitorInfo?.visitor_phone}{' '}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Email:
                      </Typography>
                      {visitorInfo?.visitor_email}{' '}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Organization Name:
                      </Typography>
                      {visitorInfo?.visitor_organization}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Designation:
                      </Typography>
                      {visitorInfo?.visitor_designation}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Type:
                      </Typography>
                      {visitorInfo?.visitor_type}
                    </Grid>

                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        DOB:
                      </Typography>
                      {moment
                        .tz(visitorInfo?.visitor_date_of_birth, 'Asia/Dhaka')
                        .format('LT')}
                      <span> | </span>
                      {moment
                        .tz(visitorInfo?.visitor_date_of_birth, 'Asia/Dhaka')
                        .format('MMM Do YY')}
                    </Grid>

                    <Grid item lg={12} sm={12} md={12}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Gender:
                      </Typography>
                      {visitorInfo?.visitor_gender}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SingleVisitorInfo;
