import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { url } from "src/Helpers/utils/Constant";
import {
  getStatusLabelFairStall,
  getStatusLabelInvoiceStatus,
} from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import moment from "moment";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { useState } from "react";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { Link } from "react-router-dom";

const FairInvoiceTableRow = ({ allAdmin, setReload }: any) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const Toast = Toaster();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (itemId: any) => {
    if (allAdmin.fair_invoice_id && user.user_admin_id) {
      try {
        const { data } = await axios.delete(
          `${url}/api/fair/stall/delete/${allAdmin.fair_invoice_id}`
        );
        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "Fair stall succesfully deleted",
          });
          setReload((prev: Boolean) => !prev);
          setOpen(false);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: `Fair stall doesn't delete`,
        });
      }
    }
  };

  return (
    <>
      <TableRow hover key={allAdmin.fair_stall_id}>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.invoice_issue_date).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.total_amount}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_invoice_payment_method}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabelInvoiceStatus(allAdmin.fair_invoice_status)}
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_fair_member_contact_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title='view' arrow>
            <Link
              to={`/fairinvoice/list/${allAdmin.user_fair_member_id.toString()}`}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title='Delete' arrow>
            <IconButton
              onClick={handleClickOpen}
              sx={{
                "&:hover": { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color='inherit'
              size='small'
            >
              <DeleteTwoToneIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <DeleteConfirmation
        open={open}
        handleClose={handleClose}
        text='Are you want delete this fair invoice?'
        handleDeleteFun={handleDelete}
      />
    </>
  );
};

export default FairInvoiceTableRow;
