import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import {
  IInvoiceData,
  IpaymentableItem,
  IuserMemberList,
} from 'src/Types/module/invoice/invoiceTypes';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios, { all } from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';

interface member {
  user_member_id: number;
  user_member_company_name: string;
  user_member_representative_name: string;
}

const invoiceData: IInvoiceData = {
  id: 0,
  atab_payment_invoice_item_name: '',
  atab_payment_invoice_item_paymentable_item_id: 0,
  atab_payment_invoice_item_amount: 0,
};
const CreateManualInvoice = ({ handleClose, reload }: any) => {
  const { user } = useAuthContext();
  const [invoiceInfo, setInvoiceInfo] = useState<IInvoiceData[]>([invoiceData]);
  // const [discount, setDiscount] = useState<string>('');
  const [userMember, setUserMember] = useState<IuserMemberList[]>([]);
  const [membersId, setMembersId] = useState<member | null>(null);
  const [loading, setLoading] = useState(false);
  const [allPaymentableItem, setAllPaymentableItem] = useState<
    IpaymentableItem[]
  >([]);

  // payment able item
  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/atab/payment/get/all/paymentable/item`
      );

      if (data.success) {
        setAllPaymentableItem(data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/all`
      );

      if (data.success) {
        setUserMember(data.data);
      }
    })();
  }, []);

  const addInvoice = (
    id: number,
    field: string | number,
    value: string | number,
    payId?: string | number
  ) => {
    const newData = invoiceInfo.map((item) => {
      if (item.id === id) {
        if (payId) {
          item.atab_payment_invoice_item_paymentable_item_id = payId;
        }
        item[field] = value;
        return item;
      } else {
        return item;
      }
    });

    setInvoiceInfo(newData);
  };

  const addNewItem = () => {
    setInvoiceInfo([
      ...invoiceInfo,
      {
        id: invoiceInfo.length,
        atab_payment_invoice_item_name: '',
        atab_payment_invoice_item_amount: 0,
        atab_payment_invoice_item_paymentable_item_id: 0,
      },
    ]);
  };

  const removeInvoiceInput = (id: number) => {
    setInvoiceInfo(invoiceInfo.filter((_item, index) => index !== id));
  };

  const handelSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const allData = {
      // discount,
      user_member_id: membersId.user_member_id,
      invoice_items: invoiceInfo.map((item) => {
        return {
          atab_payment_invoice_item_name: item.atab_payment_invoice_item_name,
          atab_payment_invoice_item_amount:
            item.atab_payment_invoice_item_amount,
          atab_payment_invoice_item_paymentable_item_id:
            item.atab_payment_invoice_item_paymentable_item_id,
        };
      }),
    };

    try {
      const { data } = await axios.post(
        `${url}/api/atab/invoice/create/manual/for-admin?user_admin_id=${user.user_admin_id}`,
        allData
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: `Invoice Successfully created`,
        });
        setLoading(false);
        reload();
        handleClose();
      } else {
        Toaster().fire({
          icon: 'error',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });

      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>ATF - Create invoice</title>
      </Helmet>

      <Box component='form' onSubmit={handelSubmit} p={2} minWidth={400}>
        <Grid container spacing={2}>
          <Grid xs={12} lg={12} item>
            <Autocomplete
              placeholder='Select Member'
              fullWidth
              value={membersId}
              onChange={(event: any, newValue: any) => {
                setMembersId(newValue);
              }}
              id='controllable-states-demo'
              options={userMember}
              getOptionLabel={(option) => option.user_member_company_name}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant='filled'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label='Select Company' fullWidth />
              )}
            />
          </Grid>
        </Grid>
        <Box mt={6}>
          {invoiceInfo.map((sInvoice, index: number) => {
            return (
              <Box key={sInvoice.id} sx={{ position: 'relative' }} mb={6}>
                <Autocomplete
                  placeholder='Select invoice item'
                  fullWidth
                  onChange={(event: any, newValue: any) => {
                    addInvoice(
                      sInvoice.id,
                      'atab_payment_invoice_item_name',
                      newValue.atab_paymentable_item_name,
                      newValue.atab_paymentable_item_id
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={allPaymentableItem}
                  getOptionLabel={(option) => option.atab_paymentable_item_name}
                  renderOption={(props, option) => (
                    <Box component='li' {...props}>
                      {option.atab_paymentable_item_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select invoice item'
                      fullWidth
                    />
                  )}
                />
                {/* <TextField
                  id='outlined-basic1'
                  label='Enter item name'
                  variant='outlined'
                  type='text'
                  required
                  sx={{ pb: 1 }}
                  fullWidth
                  onChange={(e) =>
                    addInvoice(
                      sInvoice.id,
                      sInvoice.atab_payment_invoice_item_name,
                      e.target.value
                    )
                  }
                /> */}
                <TextField
                  id='outlined-basic1'
                  label='Enter item amount'
                  variant='outlined'
                  type='number'
                  defaultValue={0}
                  fullWidth
                  sx={{ mt: 2 }}
                  onChange={(e) =>
                    addInvoice(
                      sInvoice.id,
                      'atab_payment_invoice_item_amount',
                      e.target.value
                    )
                  }
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: -35,
                    right: 0,
                  }}
                >
                  {index > 0 ? (
                    <Button
                      variant='outlined'
                      color='error'
                      size='small'
                      onClick={() => removeInvoiceInput(index)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      variant='outlined'
                      onClick={addNewItem}
                      size='small'
                    >
                      Add
                    </Button>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>

        {loading ? (
          <LoadingButton
            loading
            loadingIndicator='Creating...'
            variant='contained'
            disabled
            fullWidth
          >
            <span>Creating...</span>
          </LoadingButton>
        ) : (
          <Button type='submit' variant='contained' disableElevation fullWidth>
            Create
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreateManualInvoice;
