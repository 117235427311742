import { ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
} from '@mui/material';

import FairInvoiceTableRow from './FairInvoiceTableRow';
import { fairInvoice } from 'src/Types/module/fairInvoice/fairInvoiceType';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';

const FairInvoiceTable = ({
  allAdmin,
  loading,
  setStatus,
  status,
  setReload,
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const statusOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'available',
      name: 'Available',
    },
    {
      id: 'reserved',
      name: 'Reserved',
    },
    {
      id: 'booked',
      name: 'Booked',
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allAdmin, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '320px',
            }}
          >
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label='Status'
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title='Fair invoice List'
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Invoice issue date</TableCell>
              <TableCell>Total amount</TableCell>
              <TableCell>Payment method</TableCell>
              <TableCell>Invoice status</TableCell>
              <TableCell>Member contact name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : allAdmin.length ? (
            <TableBody>
              {paginatedInvoice.map((allAdmin: fairInvoice) => {
                return (
                  <FairInvoiceTableRow
                    key={allAdmin.fair_invoice_id}
                    allAdmin={allAdmin}
                    setReload={setReload}
                  />
                );
              })}
            </TableBody>
          ) : <TableNotFound />}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={allAdmin.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default FairInvoiceTable;
