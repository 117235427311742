import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { getStatusLabel } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { IFairMember } from "src/Utils/type";

interface IProps {
  allAdmin: IFairMember;
}

const FairMemberTableRow = ({ allAdmin }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <TableRow hover key={allAdmin.user_fair_member_id}>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_fair_member_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_fair_member_contact_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_fair_member_contact_email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_fair_member_contact_number}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.user_fair_member_created_at).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {getStatusLabel(allAdmin.user_fair_member_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title='view' arrow>
            <Link
              to={`/exhibitor/list/${allAdmin.user_fair_member_id.toString()}`}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FairMemberTableRow;
