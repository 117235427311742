import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { IFairEntry } from '../Types/AllReportTypes';
import dayjs from 'dayjs';

type IProps = {
  fairEntry: IFairEntry;
};

const FairEntryTableRow = ({ fairEntry }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairEntry.fair_entry_id}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairEntry.visitor_first_name}
          {'  '}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {dayjs(fairEntry.entry_time).format('YYYY-MM-DD')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {dayjs(fairEntry.entry_time).format('hh:mm:ss a')}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairEntry.visitor_id}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default FairEntryTableRow;
