import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import EventInfoUpdate from './EventInfoUpdate';
import { ISingleFairEvent } from 'src/Utils/type';
import { imgUrl } from 'src/Helpers/utils/Constant';
import dayjs from 'dayjs';
import { getStatusLabel } from 'src/Utils/Combined';

interface IProps {
  handleEditModeChange: Function;
  singleEvent: ISingleFairEvent;
}

const SingleFairEventInfo = ({ handleEditModeChange, singleEvent }: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(singleEvent);
  return (
    <>
      <Container>
        <Card>
          <Box textAlign='end' sx={{ p: 2 }}>
            <Tooltip title='Edit'>
              <IconButton onClick={handleOpen} sx={{ cursor: 'pointer' }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <CardContent>
            <Typography
              textAlign='center'
              gutterBottom
              variant='h2'
              component='div'
            >
              Single Event Information
            </Typography>

            <Box sx={{ mx: 3, mt: 5 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event Id:
                  </Typography>
                  {singleEvent?.event_id}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event title :
                  </Typography>
                  {singleEvent?.event_title}
                </Grid>
                {singleEvent?.event_type && (
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Event type:
                    </Typography>
                    {singleEvent?.event_type}
                  </Grid>
                )}
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event date:
                  </Typography>
                  {moment(singleEvent?.event_date).format('LL')}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event Start Time:
                  </Typography>
                  {moment(singleEvent.event_start_time, 'HH:mm a').format(
                    'hh:mm a'
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event End Time:
                  </Typography>
                  {moment(singleEvent.event_end_time, 'HH:mm a').format(
                    'hh:mm a'
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event venue:
                  </Typography>
                  {singleEvent?.event_venu}
                </Grid>
                {singleEvent?.event_date && (
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Event Created date:
                    </Typography>
                    {moment(singleEvent?.event_created_at).format('LL')}
                  </Grid>
                )}

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Event Status:
                  </Typography>
                  {getStatusLabel(singleEvent?.event_status)}
                </Grid>

                <Grid item lg={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                      gap: 2,
                    }}
                  >
                    <img
                      width={250}
                      height={150}
                      style={{
                        borderRadius: '5px',
                      }}
                      src={
                        singleEvent.event_photo
                          ? `${imgUrl}/event_files/${singleEvent.event_photo}`
                          : '/static/images/avatars/avatar2.png'
                      }
                      alt=''
                    />
                    <Box>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event description:
                      </Typography>
                      {singleEvent?.event_description}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle sx={{ p: 4 }} id='responsive-dialog-title'>
            <Typography sx={{ mb: 3 }} variant='h4'>
              Update Fair Event information
            </Typography>
            <Divider />
          </DialogTitle>

          <DialogContent>
            <EventInfoUpdate
              handleEditModeChange={handleEditModeChange}
              handleClose={handleClose}
              singleEvent={singleEvent}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default SingleFairEventInfo;
