import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from 'react-router-dom';
import { IVisitorByExhibitor } from 'src/Utils/type';
import { ILead } from './types/leadtype';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
type IProps = {
  leadByExhibitor: ILead[];
};

function LeadByExhibitor({ leadByExhibitor }: IProps) {
  const theme = useTheme();
  const [selectedLeadID, setSelectedLeadID] = useState<number | null>(null);

  const handleOpen = (leadID: number) => {
    setSelectedLeadID(leadID);
  };

  const handleClose = () => {
    setSelectedLeadID(null);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <TableContainer sx={{ marginBottom: 10 }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
          <TableRow>
            <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
              Lead ID
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              name
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Gmail
            </TableCell>
            {/* <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Status
            </TableCell> */}
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Phone
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              organization
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Remarks
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Products
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leadByExhibitor.map((details: ILead) => (
            <TableRow hover key={details.lead_id}>
              <TableCell>{details.lead_id}</TableCell>
              <TableCell align='center'>{details?.name}</TableCell>
              <TableCell align='center'>{details?.email}</TableCell>
              {/* <TableCell align='center'>{details?.lead_status}</TableCell> */}
              <TableCell align='center'>
                {details?.phone ? details?.phone : 'N/A'}
              </TableCell>
              <TableCell align='center'>
                {details?.organization ? details?.organization : 'N/A'}
              </TableCell>
              <TableCell align='center'>
                {details?.lead_remarks ? details?.lead_remarks : 'N/A'}
              </TableCell>
              <TableCell align='center'>
                {' '}
                <Box textAlign='end' sx={{ p: 2 }}>
                  <Tooltip title='Edit'>
                    <IconButton onClick={() => handleOpen(details.lead_id)}>
                      <VisibilityIcon sx={{ cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedLeadID !== null && (
        <Dialog
          fullScreen={fullScreen}
          open={selectedLeadID !== null}
          onClose={handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle sx={{ p: 4 }} id='responsive-dialog-title'>
            <Typography variant='h4'>Lead Products</Typography>
          </DialogTitle>
          <DialogContent>
            {leadByExhibitor.length > 0 &&
              leadByExhibitor
                .filter((lead) => lead.lead_id === selectedLeadID)
                .map((lead) => (
                  <TableContainer key={lead.lead_id} sx={{ marginBottom: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Product ID</TableCell>
                          <TableCell>Product Name</TableCell>
                          {/* <TableCell>Product Price</TableCell> */}
                          <TableCell>Item Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lead.products.map((product) => (
                          <TableRow key={product.fair_member_product_id}>
                            <TableCell>
                              {product.fair_member_product_id}
                            </TableCell>
                            <TableCell>
                              {product.fair_member_product_name}
                            </TableCell>
                            {/* <TableCell>
                              {product.fair_member_product_price}
                            </TableCell> */}
                            <TableCell>
                              {product.user_fair_member_product_type}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </TableContainer>
  );
}

export default LeadByExhibitor;
