import { useRef, useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { Iuser } from 'src/components/Context/AuthContext/AuthTypes';
import { useNavigate } from 'react-router-dom';
import { AUTH_USER_FAILED, imgUrl, url } from 'src/Helpers/utils/Constant';
const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { user: authUser, dispatch: authDispatch } = useAuthContext();

  const [user, setUser] = useState<Iuser>({});
  const navigate = useNavigate();

  useEffect(() => {
    setUser(authUser);
  }, [authUser]);

  const avatar = '/static/images/avatars/1.jpg';
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignOut = () => {
    localStorage.removeItem('admin_auth');
    authDispatch?.({
      type: AUTH_USER_FAILED,
    });

    navigate('/login');
  };

  const handleNotificationOpen = () => {
    setNotificationOpen(true);
  };
  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };
  const notificationCount = 5;
  return (
    <>
      {/* <Box sx={{ mx: 3 }}>
        <Button>
          <Badge
            onClick={handleNotificationOpen}
            badgeContent={notificationCount}
            color='primary'
          >
            <NotificationsIcon />
          </Badge>
        </Button>
        <Popover
          open={notificationOpen}
          onClose={handleNotificationClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          sx={{ mt: 8, ml: { md: -8 } }}
        >
          <Box sx={{ p: 2 }}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Grid item>Notification</Grid>
              <Grid item>
                <Button size='small' color='error'>
                  {" "}
                  Clear all
                </Button>{" "}
              </Grid>
            </Grid>
            <Divider />
            <Box
              sx={{
                width: 300,
                height: 250,
                overflow: "scroll",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#efefef",
                  borderRadius: 2,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#01579b",
                  borderRadius: 2,
                },
              }}
            >
              <ListItem button>Notification 1</ListItem>
              <Divider />
              <ListItem button>Notification 2</ListItem>
              <Divider />

              <ListItem button>Notification 3</ListItem>
              <Divider />

              <ListItem button>Notification 4</ListItem>
              <Divider />

              <ListItem button>Notification 1</ListItem>
              <Divider />
              <ListItem button>Notification 2</ListItem>
              <Divider />

              <ListItem button>Notification 3</ListItem>
              <Divider />

              <ListItem button>Notification 4</ListItem>
            </Box>

            <Divider />
            <Box
              sx={{
                textAlign: "center",
                mt: 1,
              }}
            >
              <Button size='small'>View all notification</Button>
            </Box>
          </Box>
        </Popover>
      </Box> */}

      <UserBoxButton color='secondary' ref={ref} onClick={handleOpen}>
        <Avatar
          variant='rounded'
          alt={user.user_admin_name}
          src={
            user.user_admin_photo
              ? `${imgUrl}/admin_files/${user.user_admin_photo}`
              : ''
          }
        />

        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.user_admin_name}</UserBoxLabel>
            {/* <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription> */}
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>

      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display='flex'>
          <Avatar
            variant='rounded'
            alt={user.user_admin_name}
            src={
              user.user_admin_photo
                ? `${imgUrl}/admin_files/${user.user_admin_photo}`
                : ''
            }
          />
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.user_admin_name}</UserBoxLabel>
            {/* <UserBoxDescription variant='body2'>
              {user.jobtitle}
            </UserBoxDescription> */}
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component='nav'>
          <ListItem button to='/profile/details' component={NavLink}>
            <AccountBoxTwoToneIcon fontSize='small' />
            <ListItemText primary='My Profile' />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button onClick={() => handleSignOut()} color='primary' fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
