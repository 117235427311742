import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { IVisitorByExhibitor } from 'src/Utils/type';

type IProps = {
  visitorByExhibitor: IVisitorByExhibitor[];
};

function VisitorByExhibitor({ visitorByExhibitor }: IProps) {
  const theme = useTheme();
  return (
    <TableContainer sx={{ marginBottom: 10 }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
          <TableRow>
            <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
              Visitor ID
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Visitor name
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Gmail
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Gender
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Phone
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align='center'
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitorByExhibitor.map((details: IVisitorByExhibitor) => (
            <TableRow hover key={details.visitor_id}>
              <TableCell>{details.visitor_id}</TableCell>
              <TableCell align='center'>
                {details?.visitor_first_name} {details?.visitor_last_name}
              </TableCell>
              <TableCell align='center'>{details?.visitor_email}</TableCell>
              <TableCell align='center'>{details?.visitor_gender}</TableCell>
              <TableCell align='center'>
                {details?.visitor_phone ? details?.visitor_phone : 'N/A'}
              </TableCell>
              <TableCell align='center'>
                <Link to={`/visitor/list/${details.visitor_id}`}>
                  <Tooltip title='view' arrow>
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter,
                        },
                        color: theme.palette.primary.main,
                      }}
                      color='inherit'
                      size='small'
                    >
                      <VisibilityIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default VisitorByExhibitor;
