import { Container, Grid } from "@mui/material";
import React from "react";
import VisitorInvoiceShow from "./VisitorInvoiceShow";
import FairInvoiceShow from "./VisitorInvoiceShow";

const VisitorInvoice = () => {
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <VisitorInvoiceShow />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VisitorInvoice;
