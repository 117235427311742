import { Button } from '@mui/material';
import Excel from 'exceljs';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import axios from 'axios';
import GlobalLoader from '../SuspenseLoader/GlobalLoader';
import dayjs from 'dayjs';

const ExcelGeneratorforEventJoinedVisitor = ({
  getDownloadData,
}: {
  getDownloadData: any;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const data = await getDownloadData();

    console.log(data);
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet('Fair_joined_Visitor_List');

      const titleRow = worksheet.addRow([
        'FAIR EVENT JOINED VISITOR ID',
        'FAIR EVENT ID',
        'FAIR EVENT JOINED VISITOR STATUS',
        'VISITOR First Name',
        'VISITOR Last Name',
        'VISITOR EMAIL',
        'VISITOR ORGANIZATION',
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      await Promise.all(
        data.map(async (item) => {
          const row = worksheet.addRow([
            item.fair_event_joined_visitor_id,
            item.fair_event_id,
            item.fair_event_joined_visitor_status,
            item.visitor_first_name,
            item.visitor_last_name,
            item.visitor_email,
            item.visitor_organization,
          ]);
        })
      );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `Fair_joined_Visitor_List.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant='outlined'>
          Download excel
        </Button>
      )}
    </>
  );
};

export default ExcelGeneratorforEventJoinedVisitor;
