import { useContext } from 'react';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ListSubheader,
  List,
  Button,
  ListItem,
  Typography,
  Accordion,
  Link,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/components/Context/SidebarContext';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { menuWrapper, submenuWrapper } from 'src/Utils/DashboardUtils';
import DashboardIcon from '@mui/icons-material/Dashboard';

const MenuWrapper = menuWrapper();
const SubMenuWrapper = submenuWrapper();

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useAuthContext();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <MenuWrapper>
        <List component='div'>
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/dashboard'
                  startIcon={<DashboardIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {user?.permissions?.modules?.includes('all-admin') && (
          <List
            component='div'
            subheader={
              <ListSubheader component='div' disableSticky>
                ADMIN
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'panel9'}
                  onChange={handleChange('panel9')}
                >
                  {user.permissions.modules.includes('role-permission') && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Admin
                      </Typography>
                    </AccordionSummary>
                  )}

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      {user.permissions.subModules.includes(
                        'role-permission'
                      ) && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/admin-list/'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Admin list
                          </Button>
                        </ListItem>
                      )}
                      {/* {user.permissions.subModules.includes('audit-trail') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/admin-list/audit-trail'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Audit Trail
                          </Button>
                        </ListItem>
                      )} */}
                    </List>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'panel10'}
                  onChange={handleChange('panel10')}
                >
                  {user.permissions.modules.includes('role-permission') && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Role & Permission
                      </Typography>
                    </AccordionSummary>
                  )}
                  {user.permissions.modules.includes('role-permission') && (
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        {user.permissions.modules.includes(
                          'role-permission'
                        ) && (
                          <>
                            <ListItem component='div'>
                              <Button
                                sx={{ paddingLeft: '18px!important' }}
                                disableRipple
                                component={RouterLink}
                                className='submodule-name'
                                onClick={closeSidebar}
                                to='/admin-list/role'
                                startIcon={<TableChartTwoToneIcon />}
                              >
                                Role List
                              </Button>
                            </ListItem>
                            <ListItem component='div'>
                              <Button
                                sx={{ paddingLeft: '18px!important' }}
                                disableRipple
                                component={RouterLink}
                                className='submodule-name'
                                onClick={closeSidebar}
                                to='/admin-list/permission'
                                startIcon={<TableChartTwoToneIcon />}
                              >
                                Permission List
                              </Button>
                            </ListItem>
                          </>
                        )}
                      </List>
                    </AccordionDetails>
                  )}
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* fair module */}
        {user?.permissions?.modules?.includes('fair') && (
          <List
            component='div'
            subheader={
              <ListSubheader component='div' disableSticky>
                Fair
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                {/* exhibitor  */}
                {user?.permissions?.subModules?.includes('exhibitor') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel23'}
                    onChange={handleChange('panel23')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Exhibitor
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/exhibitor/list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Exhibitor list
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/exhibitor/invoice-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Exhibitor invoice list
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/exhibitor/lead-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Lead list
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* fair  */}
                {user?.permissions?.modules?.includes('fair') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel24'}
                    onChange={handleChange('panel24')}
                  >
                    {user.permissions.subModules.includes('fair') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Fair
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/fair/list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Fair list
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            className='submodule-name'
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to='/fair/stall-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Stall list
                          </Button>
                        </ListItem>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/fair/stall-application'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Stall application
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* visitor  */}
                {user.permissions.modules.includes('visitor') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel25'}
                    onChange={handleChange('panel25')}
                  >
                    {user.permissions.subModules.includes('visitor') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Visitor
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/visitor/list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All visitor list
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/visitor/visitor-invoice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All visitor invoice
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* event  */}
                {user.permissions.modules.includes('event') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel26'}
                    onChange={handleChange('panel26')}
                  >
                    {user.permissions.subModules.includes('event')}
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel1bh-content'
                      id='panel1bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Event
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/fair-event/all-event-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All Event
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* Guest and Speaker */}
                {user.permissions?.modules.includes('guest-speaker') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel27'}
                    onChange={handleChange('panel27')}
                  >
                    {user.permissions.subModules.includes('guest-speaker') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Guest & Speaker
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/guest-speaker/guest-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Guest & Speaker List
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions?.modules.includes('reference') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel30'}
                    onChange={handleChange('panel30')}
                  >
                    {user.permissions.subModules.includes('reference') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Reference
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component='div'>
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/reference/reference-list'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Reference List
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions?.modules.includes('entry-fair') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel28'}
                    onChange={handleChange('panel28')}
                  >
                    {user.permissions.subModules.includes('entry-fair') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Entry fair
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/entry-fair/list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Entry fair list
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user?.permissions?.modules?.includes('report') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel29'}
                    onChange={handleChange('panel29')}
                  >
                    {user?.permissions?.subModules?.includes('report') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Report
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/report/fair-entry'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Fair Entry
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/report/fair-visitor-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Fair Visitor
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/report/fair-member-invoice'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Fair Member Invoice
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/report/event-joined-visitor'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event Joined Visitor
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/report/event-attend-visitor'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event Attend Visitor
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* payment */}
                {/* 
                {user?.permissions?.modules?.includes('payment') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel299'}
                    onChange={handleChange('panel299')}
                  >
                    {user?.permissions?.subModules?.includes('payment') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Payment
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/payment/payment-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Payment Type List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )} */}

                {user?.permissions?.modules?.includes('draw') && (
                  <Accordion
                    className='accordion'
                    expanded={expanded === 'panel289'}
                    onChange={handleChange('panel289')}
                  >
                    {user?.permissions?.subModules?.includes('draw') && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='expand-icon' />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                      >
                        <Typography className='accordion-module-name'>
                          Raffle Draw
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/draw/raffle-draw-list'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Raffle Draw List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* Settings */}
        {user?.permissions?.modules?.includes('user-profile') && (
          <List
            component='div'
            subheader={
              <ListSubheader component='div' disableSticky>
                Setting
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className='accordion-submodule' component='div'>
                <Accordion
                  className='accordion'
                  expanded={expanded === 'panel33'}
                  onChange={handleChange('panel33')}
                >
                  {user.permissions.modules.includes('user-profile') && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        User settings
                      </Typography>
                    </AccordionSummary>
                  )}

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component='div'>
                      {user.permissions.subModules.includes('user-profile') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/profile'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            User Profile
                          </Button>
                        </ListItem>
                      )}
                      {/* {user.permissions.subModules.includes('sms') && (
                        <ListItem component='div'>
                          <Button
                            sx={{ paddingLeft: '18px!important' }}
                            disableRipple
                            component={RouterLink}
                            className='submodule-name'
                            onClick={closeSidebar}
                            to='/address'
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Address
                          </Button>
                        </ListItem>
                      )} */}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
