import { TableCell, TableRow, Typography, useTheme } from '@mui/material';

type IProps = {
  fairVisitor: any;
};

const VisitorTableRow = ({ fairVisitor }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.fair_event_joined_visitor_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.fair_event_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.fair_event_joined_visitor_status}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_first_name}
          {'  '}
          {fairVisitor.visitor_last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_email}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_organization || 'N/A'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default VisitorTableRow;
