import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';

type IProps = {
  fairVisitor: any;
};

const EventAttendTableRow = ({ fairVisitor }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.fair_event_attend_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {dayjs(fairVisitor.fair_event_attend_id).format('hh:mm:ss a')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.fair_event_joined_visitor_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_first_name}
          {'  '}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {dayjs(fairVisitor.fair_event_joined_visitor_join_date).format(
            'YYYY-MM-DD'
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default EventAttendTableRow;
