import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { fairInvoice } from 'src/Types/module/fairInvoice/fairInvoiceType';
import FairInvoiceTable from './FairInvoiceTable';

const FairInvoiceShow = () => {
  const [allAdmins, setAllAdmins] = useState<fairInvoice[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('all');
  const [reload, setReload] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(
            `${url}/api/atab/fair-invoice/get/all/for-admin/status/${status}`
          )
          .then(function (res) {
            if (res.data.success) {
              setAllAdmins(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmins([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status, reload]);

  const allAdmin: fairInvoice[] = allAdmins;
  return (
    <>
      <Card>
        <FairInvoiceTable
          loading={loading}
          allAdmin={allAdmin}
          setStatus={setStatus}
          status={status}
          setReload={setReload}
        />
      </Card>
    </>
  );
};

export default FairInvoiceShow;
