import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { IFairMemberInfo } from 'src/Utils/type';
import { id } from 'date-fns/locale';
import { useParams } from 'react-router';

interface IProps {
  details: any;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
  showModal: boolean;
  setShowModal: Function;
}

const UpdateReference = ({
  details,
  handleClose,
  setReload,
  setShowModal,
}: IProps) => {
  const { register, handleSubmit, reset } = useForm();
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const Toast = Toaster();
  const { id } = useParams();

  const onSubmit = async (inputs: any) => {
    if (user.user_admin_id) {
      try {
        setLoading(true);
        const { organization, name, designation, limit, status } = inputs;

        interface BodyData {
          organization?: string;
          name?: string;
          designation?: string;
          limit?: number;
          status?: number;
        }

        const bodyData: BodyData = {};

        if (organization !== '') {
          bodyData.organization = organization;
        }
        if (name !== '') {
          bodyData.name = name;
        }
        if (designation !== '') {
          bodyData.designation = designation;
        }
        if (limit !== '') {
          bodyData.limit = limit;
        }
        if (status !== '') {
          bodyData.status = status;
        }

        const { data } = await axios.patch(
          `${url}/api/fair/event/for-admin/reference/${id}`,
          bodyData
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Successfully Updated`,
          });
          setLoading(false);
          setReload((prev: Boolean) => !prev);
          reset();
          handleClose();
          setShowModal(false);
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Organization'
                  id='organization'
                  {...register('organization')}
                  type='text'
                  defaultValue={details.organization}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  id='name'
                  label='name'
                  {...register('name')}
                  type='text'
                  defaultValue={details.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  id='designation'
                  label='Designation'
                  {...register('designation')}
                  type='text'
                  defaultValue={details.designation}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  id='limit'
                  label='Limit'
                  {...register('limit')}
                  type='number'
                  defaultValue={details.limit}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor='limit'>Status</InputLabel>
                  <Select
                    native
                    {...register('status')}
                    inputProps={{
                      name: 'status',
                      id: 'status',
                    }}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update
              </Button>
            ) : (
              <LoadingButton
                loading
                loadingIndicator='Updating...'
                fullWidth
                variant='outlined'
                disabled
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                <span>Updating...</span>
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default UpdateReference;
