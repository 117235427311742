import { Button } from '@mui/material';
import Excel from 'exceljs';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import axios from 'axios';
import GlobalLoader from '../SuspenseLoader/GlobalLoader';
import dayjs from 'dayjs';

const ExcelGeneratorforEventAttendVisitor = ({
  getDownloadData,
}: {
  getDownloadData: any;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const data = await getDownloadData();

    console.log(data);
    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet('Fair_Attend_Visitor_List');

      const titleRow = worksheet.addRow([
        'FAIR EVENT ATTEND ID',
        'FAIR EVENT ATTEND AT',
        'FAIR EVENT JOINED VISITOR ID',
        'VISITOR  NAME',
        'FAIR EVENT JOINED VISITOR JOIN DATE',
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      await Promise.all(
        data.map(async (item) => {
          const row = worksheet.addRow([
            item.fair_event_attend_id,
            dayjs(item.fair_event_attend_at).format('hh:mm:ss a'),
            item.fair_event_joined_visitor_id,
            item.visitor_first_name,
            dayjs(item.fair_event_joined_visitor_join_date).format(
              'YYYY-MM-DD'
            ),
          ]);
        })
      );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `Fair_Attend_Visitor_List.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant='outlined'>
          Download excel
        </Button>
      )}
    </>
  );
};

export default ExcelGeneratorforEventAttendVisitor;
