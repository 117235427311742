import { Card, Container, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import { exhibitorInvoice } from 'src/Utils/type';
import { IFairVisitorList } from '../Types/AllReportTypes';
import FairVisitorTable from './FairVisitorTable';

const FairVisitor = () => {
  const [entryFairVisitorList, setEntryFairVisitorList] = useState<
    IFairVisitorList[] | null
  >([]);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/fair/report/visitor-invoice`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setEntryFairVisitorList(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <FairVisitorTable
              loading={loading}
              entryFairVisitorList={entryFairVisitorList}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FairVisitor;
