import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import '../src/Styles/Login_reg.css';
import '../src/Styles/responsive.css';
import '../src/Styles/Dashboard.css';
import '../src/Styles/bind.css';
import '../src/Styles/index.css';
import { routers } from './routers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
function App() {
  // const content = useRoutes(router);

  const router = routers;

  return (
    <>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProvider>

      {/* <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider> */}
    </>
  );
}

export default App;
