import { useState } from 'react';
import { Button, CssBaseline, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import East from '@mui/icons-material/East';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import { LottiedefaultOptions } from 'src/Utils/Combined';
import Toaster from 'src/Toaster/Toaster';
import { muiStyles } from 'src/Utils/Styles';
import { url } from 'src/Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
const useStyles = muiStyles;

const ForgetPassword = () => {
  const Toast = Toaster();
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultOptions = LottiedefaultOptions;

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (email) {
      try {
        const { data } = await axios.post(
          `${url}/api/atab/common/send-email-otp`,
          {
            email,
            type: 'forget_admin',
          }
        );

        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `We sent verification code to ${email}`,
          });

          setIsLoading(false);
          navigate(`/forget-password/otp?email=${email}`);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        Toast.fire({
          icon: 'error',
          title: err.response.data.message,
        });
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>ATF - Forget Password</title>
      </Helmet>
      <div className='login-page'>
        <div className='login-wrapper'>
          <div className='login-right-col'>
            <Container maxWidth='sm' component='main'>
              <CssBaseline />

              <div className='lottie-img'>
                <Lottie options={defaultOptions} height={300} width={300} />
              </div>
              <Box>
                <Box component='form' onSubmit={handleSubmit}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    type='email'
                    name='email'
                    id='email'
                    label='Enter your email'
                    onChange={(e: any) => setEmail(e.target.value)}
                    autoComplete='email'
                    autoFocus
                    variant='filled'
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />

                  {!isLoading ? (
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                    >
                      Forget password
                    </Button>
                  ) : (
                    <LoadingButton
                      loading={isLoading}
                      fullWidth
                      color='secondary'
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                      style={{
                        background: '#1113a2!important',
                        color: 'white!important',
                      }}
                      loadingPosition='end'
                      variant='contained'
                    >
                      Forget password
                    </LoadingButton>
                  )}
                </Box>

                <Grid container>
                  <Grid item xs={12} sm={6} md={12}>
                    <Link to='/login'>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '20px',
                        }}
                        className='text-main'
                      >
                        {'Go Back'}
                        <East sx={{ marginLeft: '5px' }} fontSize='small' />
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
