import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { getStatusLabel, getStatusLabelAdminFun } from 'src/Utils/Combined';
import EditIcon from '@mui/icons-material/Edit';
import { Dispatch, SetStateAction, useState } from 'react';
import FairMemberInfoUpdateForm from './FairMemberInfoUpdateForm';
import { useNavigate } from 'react-router';
import { IFairMemberInfo } from 'src/Utils/type';

interface IProps {
  fairInfo: IFairMemberInfo;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const FairMemberInfo = ({ fairInfo, setReload }: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Card>
        <Box textAlign='end' sx={{ p: 2 }}>
          <Tooltip title='Edit'>
            <IconButton onClick={handleOpen}>
              <EditIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <CardContent>
          <Typography
            textAlign='center'
            gutterBottom
            variant='h2'
            component='div'
          >
            Single Exhibitor Information
          </Typography>

          <Box sx={{ m: 3 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Member ID:
                </Typography>
                {fairInfo?.user_fair_member_id}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Contact name:
                </Typography>
                {fairInfo?.user_fair_member_contact_name}
              </Grid>
              {fairInfo?.user_fair_member_contact_designation && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Member designation:
                  </Typography>
                  {fairInfo?.user_fair_member_contact_designation}
                </Grid>
              )}
              {fairInfo?.user_fair_member_created_at && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Created date:
                  </Typography>
                  {moment(fairInfo?.user_fair_member_created_at).format('LL')}
                </Grid>
              )}
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Member status:
                </Typography>
                {getStatusLabel(fairInfo?.user_fair_member_status)}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Contact number:
                </Typography>
                {fairInfo?.user_fair_member_contact_number}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle sx={{ p: 4 }} id='responsive-dialog-title'>
          <Typography variant='h4'>
            Update exhibitor member information
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FairMemberInfoUpdateForm
            details={fairInfo}
            setReload={setReload}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FairMemberInfo;
