import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { Box } from '@mui/system';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { Link } from 'react-router-dom';
import { getStatusLabel } from 'src/Utils/Combined';
import { ILeadList } from './types/LeadType';

type IProps = {
  lead: ILeadList;
  setAllLead: any;
  allLead: any;
};

const LeadTableRow = ({ lead, allLead, setAllLead }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {lead?.lead_id}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {lead?.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {lead?.phone ? lead?.phone : 'N/A'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {lead?.organization ? lead?.organization : 'N/A'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {getStatusLabel(lead?.lead_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <Link to={`${lead?.lead_id}`}>
              <Tooltip title='view' arrow>
                <IconButton
                  sx={{
                    '&:hover': {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color='inherit'
                  size='small'
                >
                  <VisibilityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default LeadTableRow;
