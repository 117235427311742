import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import PrivateRoute from './Reducers/AuthReducer/PrivateRoute';
import ForgetPassword from './content/pages/Forget/ForgetPassword';
import ChangePassword from './content/pages/changePassword/ChangePassword';
import OtpSend from './content/pages/otp/OtpSend';
// import CompanyInfoDetails from './content/modules/Members/subModules/memberList/singleMember';
// import SingleMeetingPage from './content/modules/Members/Meeting/singleMeeting/Index';
import SingleAdmin from './content/modules/Admin/singleAdmin/SingleAdmin';
import FairStallList from './content/modules/FairStall/FairStallList';
import FairInvoice from './content/modules/FairInvoice/FairInvoiceShow';
import FairInvoiceByFairMember from './content/modules/FairInvoice/FairInvoiceByFairMember/Index';
import AdminFair from './content/modules/Fair/Index';
import AllFairMember from './content/modules/AllFairMember/Index';
import SingleFairApplicationPage from './content/modules/Fair/SingleFair/Index';
import SingleFairMemberPage from './content/modules/AllFairMember/SingleFairMember/Index';
import CreateFairInvoiceByMember from './content/modules/FairInvoice/CreateFairInoice/Index';
import AllEventList from './content/modules/FairEvent/AllEventList/AllEventList';
import SingleEvent from './content/modules/FairEvent/SingleEvent/SingleEvent';
import VisitorList from './content/modules/visitor/allVisitorList/AllVisitorList';
import SingleVisitor from './content/modules/visitor/singleVisitor/SingleVisitor';
import VisitorInvoice from './content/modules/visitorInvoice/Index';
import SingleVisitorInvoicePage from './content/modules/visitorInvoice/SingleVisitor/SingleVisitor';
import ExhibitorInvoice from './content/modules/exhibitor/exhibitorInvoice/ExhibitorInvoice';
import ExhibitorSingleInvoice from './content/modules/exhibitor/exhibitorInvoice/singleInvoice/ExhibitorSingleInvoice';
import EntryFairList from './content/modules/EntryFair/EntryFair';
import StallApplication from './content/modules/Fair/stallApplication/StallApplication';
import SingleStallApplication from './content/modules/Fair/stallApplication/singleStallApplication/SingleStallApplication';
import ExhibitorSingleProduct from './content/modules/AllFairMember/SingleFairMember/SingleProductByExhibitor';

// import MemberTable from './content/modules/Members/subModules/memberList/allMember/MemberTable';
// import ActiveMemberTable from './content/modules/Members/subModules/memberList/activeMember/ActiveMemberTable';
// import MeetingTable from './content/modules/Members/Meeting/MeetingTable';
import AdminTable from './content/modules/Admin/AdminTable';
import SingleGuestAndSpeaker from './content/modules/GuestAndSpecker/SingleGuestAndSpeaker';
import AllGuestList from './content/modules/GuestAndSpecker/AllGuestAndSpeaker';
import InvoiceTable from './content/modules/Members/subModules/invoice/allInvoice/InvoiceTable';
// import PendingMemberTable from './content/modules/Members/subModules/memberList/pendingMember/PendingMemberTable';
// import RejectedMemberTable from './content/modules/Members/subModules/memberList/rejectedMember/RejectedMemberTable';
import RoleTable from './content/modules/Admin/RoleAndPermission/Role/RoleTable';
import PermissionTable from './content/modules/Admin/RoleAndPermission/PermissionList/PermissionTable';
import AuditAndTrail from './content/modules/Admin/AuditAndTrail/AuditAndTrail';
// import InvoiceMemberTable from './content/modules/Members/subModules/memberList/invoiceMember/InvoiceMemberTable';
import PermissionRoute from './Reducers/AuthReducer/PermissionRoute';
// import BlockedMemberTable from './content/modules/Members/subModules/memberList/blockedMember/BlockedMemberTable';
import SingleRole from './content/modules/Admin/RoleAndPermission/Role/SingleRole/SingleRole';
import EventTable from './content/modules/Members/Event/EventTable';
// import NoticeTable from './content/modules/Members/Notice/NoticeTable';
// import SingleNotice from './content/modules/Members/Notice/SingleNotice/SingleNotice';
import SingleEventPage from './content/modules/Members/Event/singleEvent/Index';
// import RequestCompanyInfo from './content/modules/Members/UpdateRequest/CompanyInformation/RequestCompanyInfo';
// import RequestSingleCompanyInfo from './content/modules/Members/UpdateRequest/CompanyInformation/RequestSingleCompanyInfo';
// import RequestOwnerInfo from './content/modules/Members/UpdateRequest/OwnerInformation/RequestOwnerInfo';
// import RequestSingleOwnerInfo from './content/modules/Members/UpdateRequest/OwnerInformation/RequestSingleOwnerInfo';
// import RequestCompanyDocsInfo from './content/modules/Members/UpdateRequest/CompanyDocs/RequestCompanyDocsInfo';
// import RequestSingleCompanyDocsInfo from './content/modules/Members/UpdateRequest/CompanyDocs/RequestSingleCompanyDocsInfo';
// import RequestTradeLicenseInfo from './content/modules/Members/UpdateRequest/TradeLicenseInfo/RequestTradeLicenseInfo';
// import RequestSingleTradeLicenseInfo from './content/modules/Members/UpdateRequest/TradeLicenseInfo/RequestSingleTradeLicenseInfo';
// import RequestBusinessLicenseInfo from './content/modules/Members/UpdateRequest/BusinessLicense/RequestBusinessLicenseInfo';
// import RequestSingleBusinessLicenseInfo from './content/modules/Members/UpdateRequest/BusinessLicense/RequestSingleBusinessLicenseInfo';
// import RequestOtherBusinessLicenseInfo from './content/modules/Members/UpdateRequest/OtherBusinessLicense/RequestOtherBusinessLicenseInfo';
// import RequestSingleOtherBusinessLicenseInfo from './content/modules/Members/UpdateRequest/OtherBusinessLicense/RequestSingleOtherBusinessLicenseInfo';
// import RequestCivilAviationInfo from './content/modules/Members/UpdateRequest/CivilAviationInformation/RequestCivilAviationInfo';
// import RequestSingleCivilAviationInfo from './content/modules/Members/UpdateRequest/CivilAviationInformation/RequestSingleCivilAviationInfo';
// import RequestProposerInfo from './content/modules/Members/UpdateRequest/ProposerInformation/RequestProposerInfo';
// import RequestSingleProposerInfo from './content/modules/Members/UpdateRequest/ProposerInformation/RequestSingleProposerInfo';
// import IdCard from './content/modules/Members/IDCard/IdCard';
// import SingleIdCard from './content/modules/Members/IDCard/SingleIdCard';
import VisitorReportList from './content/modules/Report/VisitorReportList';
import FairVisitor from './content/modules/Report/Fair Visitor/FairVisitor';
import FairMemberInvoice from './content/modules/Report/Fair_Member_Invoice/FairMemberInvoice';
import AllLeadList from './content/modules/Lead/AllLeadList';
import SinglLead from './content/modules/Lead/SingleLead';
import AllReferenceList from './content/modules/Reference/AllReferenceList';
import SingleReference from './content/modules/Reference/SingleReference';
import AllPaymentlist from './content/modules/Payment/AllPaymentlist';
import AllDrawlist from './content/modules/RaffleDraw/AllDrawlist';
import FairEntry from './content/modules/Report/Fair Entry/FairEntry';
import AllEventListData from './content/modules/Report/Event Joined Visitor/AllEventListData';
import SingleEventVisitor from './content/modules/Report/Event Joined Visitor/SingleEventVisitor';
import EventVisitor from './content/modules/Report/Event Joined Visitor/EventVisitor';
import EventAttendVisitor from './content/modules/Report/Event Attend Visitor/EventAttendVisitor';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const SingleInvoicePage = Loader(
  lazy(
    () =>
      import(
        './content/modules/Members/subModules/invoice/singleInvoice/SingleInvoicePage'
      )
  )
);

// login
const Login = Loader(lazy(() => import('./content/pages/Login/Login')));

// Dashboards
const DashboardOverview = Loader(
  lazy(() => import('src/content/modules/dashboard'))
);

// Applications
const UserProfile = Loader(
  lazy(() => import('src/content/modules/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/modules/Users/settings'))
);

// Status loader
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

export const routers = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='dashboard' replace />,
      },
      {
        path: 'dashboard',
        element: <DashboardOverview />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to='404' replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
    ],
  },

  {
    path: 'fairinvoice',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <FairInvoice />,
      },
      {
        path: 'list/:fairMemberId',
        element: <FairInvoiceByFairMember />,
      },
      {
        path: 'create',
        element: <CreateFairInvoiceByMember />,
      },
    ],
  },

  // This is fair module routing file
  {
    path: 'fair',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/fair',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <PermissionRoute component={<AdminFair />} route='fair' />,
      },
      {
        path: 'list/:singleFairId',
        element: (
          <PermissionRoute
            component={<SingleFairApplicationPage />}
            route='fair'
          />
        ),
      },
      {
        path: 'stall-list',
        element: <PermissionRoute component={<FairStallList />} route='fair' />,
      },
      {
        path: 'stall-application',
        element: (
          <PermissionRoute component={<StallApplication />} route='fair' />
        ),
      },
      {
        path: 'stall-application/:stallApplicationId',
        element: (
          <PermissionRoute
            component={<SingleStallApplication />}
            route='fair'
          />
        ),
      },
    ],
  },

  // Exhibitor Module
  {
    path: 'exhibitor',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/exhibitor',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<AllFairMember />} route='exhibitor' />
        ),
      },
      {
        path: 'lead-list',
        element: (
          <PermissionRoute component={<AllLeadList />} route='exhibitor' />
        ),
      },
      {
        path: 'lead-list/:id',
        element: (
          <PermissionRoute component={<SinglLead />} route='exhibitor' />
        ),
      },
      {
        path: 'list/:singleMemberFairId',
        element: (
          <PermissionRoute
            component={<SingleFairMemberPage />}
            route='exhibitor'
          />
        ),
      },
      {
        path: 'list/:singleMemberFairId/product/:ExhibitorSingleProduct',
        element: (
          <PermissionRoute
            component={<ExhibitorSingleProduct />}
            route='exhibitor'
          />
        ),
      },
      {
        path: 'invoice-list',
        element: (
          <PermissionRoute component={<ExhibitorInvoice />} route='exhibitor' />
        ),
      },
      {
        path: 'invoice-list/:singleExhibitorInvoiceId',
        element: (
          <PermissionRoute
            component={<ExhibitorSingleInvoice />}
            route='exhibitor'
          />
        ),
      },
    ],
  },

  {
    path: 'visitor',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/visitor',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<VisitorList />} route='visitor' />
        ),
      },
      {
        path: 'list/:singleVisitorId',
        element: (
          <PermissionRoute component={<SingleVisitor />} route='visitor' />
        ),
      },
      {
        path: 'visitor-invoice',
        element: (
          <PermissionRoute component={<VisitorInvoice />} route='visitor' />
        ),
      },
      {
        path: 'visitor-invoice/:visitorInvoiceId',
        element: (
          <PermissionRoute
            component={<SingleVisitorInvoicePage />}
            route='visitor'
          />
        ),
      },
    ],
  },

  {
    path: 'fair-event',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/fair-event',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'all-event-list',
        element: <PermissionRoute component={<AllEventList />} route='event' />,
      },
      {
        path: 'all-event-list/:eventId',
        element: <PermissionRoute component={<SingleEvent />} route='event' />,
      },
    ],
  },

  // Guest and Speaker
  {
    path: 'guest-speaker',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/guest-speaker',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'guest-list',
        element: (
          <PermissionRoute component={<AllGuestList />} route='guest-speaker' />
        ),
      },
      {
        path: 'guest-list/details/:id',
        element: (
          <PermissionRoute
            component={<SingleGuestAndSpeaker />}
            route='guest-speaker'
          />
        ),
      },
    ],
  },
  //reference
  {
    path: 'reference',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/reference',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'reference-list',
        element: (
          <PermissionRoute component={<AllReferenceList />} route='reference' />
        ),
      },
      {
        path: 'reference-list/details/:id',
        element: (
          <PermissionRoute component={<SingleReference />} route='reference' />
        ),
      },
    ],
  },

  // This is entry fair module routing file
  {
    path: 'entry-fair',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/entry-fair',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<EntryFairList />} route='entry-fair' />
        ),
      },
    ],
  },
  {
    path: 'report',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/report',
        element: <Navigate to='fair-visitor-list' replace />,
      },
      {
        path: 'fair-entry',
        element: <PermissionRoute component={<FairEntry />} route='report' />,
      },
      {
        path: 'fair-visitor-list',
        element: <PermissionRoute component={<FairVisitor />} route='report' />,
      },
      {
        path: 'fair-member-invoice',
        element: (
          <PermissionRoute component={<FairMemberInvoice />} route='report' />
        ),
      },
      {
        path: 'event-joined-visitor',
        element: (
          <PermissionRoute component={<EventVisitor />} route='report' />
        ),
      },
      {
        path: 'event-attend-visitor',
        element: (
          <PermissionRoute component={<EventAttendVisitor />} route='report' />
        ),
      },
    ],
  },
  {
    path: 'payment',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/payment',
        element: <Navigate to='payment-list' replace />,
      },
      {
        path: 'payment-list',
        element: (
          <PermissionRoute component={<AllPaymentlist />} route='payment' />
        ),
      },
    ],
  },
  {
    path: 'draw',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/draw',
        element: <Navigate to='raffle-draw-list' replace />,
      },
      {
        path: 'raffle-draw-list',
        element: <PermissionRoute component={<AllDrawlist />} route='draw' />,
      },
    ],
  },
  {
    path: 'support',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='conversations' replace />,
      },
    ],
  },

  {
    path: 'invoices',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='transactions' replace />,
      },
      {
        path: 'transactions',
        element: (
          <PermissionRoute
            component={<InvoiceTable />}
            route='member-invoice'
          />
        ),
      },
      {
        path: 'transactions/:id',
        element: (
          <PermissionRoute
            component={<SingleInvoicePage />}
            route='member-invoice'
          />
        ),
      },
    ],
  },

  {
    path: 'paymentable-item',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
    ],
  },

  // {
  //   path: 'update-request',
  //   element: <PrivateRoute component={<SidebarLayout />} />,
  //   children: [
  //     {
  //       path: '',
  //       element: (
  //         <PermissionRoute
  //           component={<RequestCompanyInfo />}
  //           route='member-list'
  //         />
  //       ),
  //     },
  //     {
  //       path: 'company-information',
  //       element: (
  //         <PermissionRoute
  //           component={<RequestCompanyInfo />}
  //           route='member-list'
  //         />
  //       ),
  //     },
  //     {
  //       path: 'company-information/:companyID',
  //       element: <RequestSingleCompanyInfo />,
  //     },
  //     {
  //       path: 'owner-info',
  //       element: (
  //         <PermissionRoute
  //           component={<RequestOwnerInfo />}
  //           route='member-list'
  //         />
  //       ),
  //     },
  //     {
  //       path: 'owner-info/:ownerId',
  //       element: <RequestSingleOwnerInfo />,
  //     },
  //     {
  //       path: 'company-docs',
  //       element: (
  //         <PermissionRoute
  //           component={<RequestCompanyDocsInfo />}
  //           route='member-list'
  //         />
  //       ),
  //     },
  //     {
  //       path: 'company-docs/:id',
  //       element: <RequestSingleCompanyDocsInfo />,
  //     },
  //   ],
  // },

  //Admin module route
  {
    path: 'admin-list',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PermissionRoute component={<AdminTable />} route='all-admin' />
        ),
      },
      {
        path: 'single-admin/:id',
        element: (
          <PermissionRoute component={<SingleAdmin />} route='all-admin' />
        ),
      },
      {
        path: 'audit-trail',
        element: (
          <PermissionRoute component={<AuditAndTrail />} route='audit-trail' />
        ),
      },
      {
        path: 'role',
        element: (
          <PermissionRoute component={<RoleTable />} route='role-permission' />
        ),
      },
      {
        path: 'role/:id',
        element: (
          <PermissionRoute component={<SingleRole />} route='role-permission' />
        ),
      },
      {
        path: 'permission',
        element: (
          <PermissionRoute
            component={<PermissionTable />}
            route='role-permission'
          />
        ),
      },
    ],
  },

  {
    path: 'profile',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='details' replace />,
      },
      {
        path: 'details',
        element: (
          <PermissionRoute component={<UserProfile />} route='user-profile' />
        ),
      },
      {
        path: 'settings',
        element: (
          <PermissionRoute component={<UserSettings />} route='user-profile' />
        ),
      },
    ],
  },
  {
    path: 'address',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='district' replace />,
      },

      {
        path: 'settings',
        element: (
          <PermissionRoute component={<UserSettings />} route='user-profile' />
        ),
      },
    ],
  },

  {
    path: 'login',
    element: <Login />,
  },

  {
    path: 'forget-password',
    element: <ForgetPassword />,
  },

  {
    path: 'forget-password/otp',
    element: <OtpSend />,
  },

  {
    path: 'change-password/*',
    element: <ChangePassword />,
  },

  {
    path: '*',
    element: <Status404 />,
  },
]);
