import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { IadminTableRowProps } from "src/Types/module/admin/adminTypes";
import { getStatusLabelAdminFun } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

const AdminTableRow = ({ allAdmin }: IadminTableRowProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_admin_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex" }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
              }}
              src={
                allAdmin.user_admin_photo
                  ? `${imgUrl}/admin_files/${allAdmin.user_admin_photo}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt=''
            />

            <Typography
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
            >
              {allAdmin.user_admin_name
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_admin_phone}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_admin_email}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.user_admin_role_name}
          </Typography>
        </TableCell>

        <TableCell>
          {getStatusLabelAdminFun(allAdmin.user_admin_status)}
        </TableCell>
        <TableCell>
          <Link to={`/admin-list/single-admin/${allAdmin.user_admin_id}`}>
            <Tooltip title='view' arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AdminTableRow;
