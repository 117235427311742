import { ChangeEvent, useEffect, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import LeadTableRow from './LeadTableRow';

const AllLeadList = () => {
  const [allLead, setAllLead] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/fair/lead`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllLead(data.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allLead, page, limit);

  return (
    <Box sx={{ m: 2 }}>
      <Card>
        <CardHeader title='Lead List' />

        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableLoader />
            ) : allLead.length ? (
              <TableBody>
                {paginatedInvoice.map((singleLead: any) => {
                  return (
                    <LeadTableRow
                      key={singleLead.lead_id}
                      lead={singleLead}
                      setAllLead={setAllLead}
                      allLead={allLead}
                    />
                  );
                })}
              </TableBody>
            ) : (
              <TableNotFound />
            )}
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component='div'
            count={allLead.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default AllLeadList;
