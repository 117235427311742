import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Button,
  Container,
  Grid,
  SelectChangeEvent,
} from '@mui/material';
import { Iinvoice } from 'src/Types/module/invoice/invoiceTypes';
import InvoiceTableRow from './InvoiceTableRow';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useDebounce } from 'src/hooks/useDebounce';
import { useSearchParams } from 'react-router-dom';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateInvoice from './CreateInvoice';
import CreateManualInvoice from './CreateManualInvoice';

const InvoiceTable = () => {
  const [limit, setLimit] = useState<number>(25);
  const [open, setOpen] = useState(false);
  const [openManual, setOpenManual] = useState(false);
  const [allInvoices, setAllInvoices] = useState<Iinvoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchInvoiceVal, setSearchInvoiceVal] = useState<string>('');
  const searchQuery = useDebounce(searchInvoiceVal, 1000);
  const [refresh, setRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'pending',
    page: '0',
  });
  const status = searchParams.get('status');
  const page = parseInt(searchParams.get('page'));

  useEffect(() => {
    setLoading(true);
    try {
      if (!searchQuery) {
        axios
          .get(`${url}/api/atab/invoice/get/all/for-admin/status/${status}`)
          .then(function (res) {
            if (res.data.success) {
              setAllInvoices(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllInvoices([]);
            setLoading(false);
          });
      } else {
        axios
          .get(
            `${url}/api/atab/invoice/get/all/for-admin/status/company-name/${status}/${searchQuery}`
          )
          .then(function (res) {
            if (res.data.success) {
              setAllInvoices(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllInvoices([]);
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
    }
  }, [status, searchQuery, refresh]);

  const statusOptions = [
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'cancelled',
      name: 'Cancelled',
    },
    {
      id: 'paid',
      name: 'Paid',
    },
    {
      id: 'reported',
      name: 'Reported',
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  const handleStatus = (e: SelectChangeEvent<string>) => {
    searchParams.set('status', e.target.value);
    setSearchParams(searchParams);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allInvoices, page, limit);

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <TextField
                    label='Search invoice'
                    id='standard-search'
                    type='search'
                    variant='outlined'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchInvoiceVal(e.target.value)
                    }
                  />
                  <FormControl variant='outlined' sx={{ width: '150px' }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleStatus(e)}
                      label='Status'
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={() => setOpen(true)} variant='contained'>
                    Create an invoice
                  </Button>
                  <Button
                    onClick={() => setOpenManual(true)}
                    variant='contained'
                  >
                    Create manual invoice
                  </Button>
                </Box>
              }
              title='All Member Invoices'
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Member</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Last Updated By</TableCell>
                    <TableCell>Payment method</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : paginatedInvoice.length ? (
                    <>
                      {paginatedInvoice.map((allInvoice) => (
                        <InvoiceTableRow
                          key={allInvoice.atab_payment_invoice_id}
                          allInvoice={allInvoice}
                        />
                      ))}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
                <TableBody>
                  {paginatedInvoice.map((allInvoice: any) => {
                    return (
                      <InvoiceTableRow
                        key={allInvoice.atab_payment_invoice_id}
                        allInvoice={allInvoice}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Box p={2}>
              <TablePagination
                component='div'
                count={allInvoices.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: 'Create invoice' }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateInvoice
                handleClose={setOpen}
                setAllInvoices={setAllInvoices}
              />
            </ModalsWrapper>

            {/* mamual invoice  */}
            <ModalsWrapper
              modalData={{ title: 'Create manual invoice' }}
              setShowModal={setOpenManual}
              showModal={openManual}
            >
              <CreateManualInvoice
                handleClose={() => setOpenManual(false)}
                reload={() => setRefresh((prv) => !prv)}
              />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoiceTable;
