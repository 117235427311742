import { Visibility } from '@mui/icons-material';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { Iinvoice } from 'src/Types/module/invoice/invoiceTypes';
import { Link } from 'react-router-dom';
import { getStatusLabel } from 'src/Utils/Combined';

type IProps = {
  allInvoice: Iinvoice;
};

const InvoiceTableRow = ({ allInvoice: invoiceData }: IProps) => {
  const theme = useTheme();

  return (
    <>
      {invoiceData && (
        <TableRow hover key={invoiceData.atab_payment_invoice_id}>
          <TableCell>{invoiceData.atab_payment_invoice_id}</TableCell>
          <TableCell>
            {moment(invoiceData.atab_payment_invoice_issue_date)
              .utc()
              .format('DD-MM-YYYY')}
          </TableCell>

          <TableCell>
            {invoiceData.user_member_company_name
              .toLowerCase()
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </TableCell>
          <TableCell>{invoiceData.atab_payment_invoice_grand_total}</TableCell>

          <TableCell>
            {invoiceData?.updated_by_admin_name
              ? invoiceData?.updated_by_admin_name
              : 'Not updated'}
          </TableCell>

          <TableCell>
            {invoiceData?.atab_payment_invoice_payment_method
              ? invoiceData?.atab_payment_invoice_payment_method
              : 'Unpaid'}
          </TableCell>

          <TableCell>
            {getStatusLabel(invoiceData.atab_payment_invoice_status)}
          </TableCell>
          <TableCell>
            <Tooltip title='View' arrow>
              <Link to={`${invoiceData.atab_payment_invoice_id}`}>
                <IconButton
                  sx={{
                    '&:hover': {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color='inherit'
                  size='small'
                >
                  <Visibility fontSize='small' />
                </IconButton>
              </Link>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default InvoiceTableRow;
