import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  TextField,
  Chip,
  Autocomplete,
  Grid,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import {
  IpaymentableItem,
  IuserMemberList,
} from 'src/Types/module/invoice/invoiceTypes';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';

interface IProps {
  user_member_id: number;
  user_member_company_name: string;
  user_member_representative_name: string;
}
const CreateInvoice = ({ handleClose, setAllInvoices }) => {
  const { user } = useAuthContext();
  const [allPaymentableItem, setAllPaymentableItem] = useState<
    IpaymentableItem[]
  >([]);
  const [userMember, setUserMember] = useState<IuserMemberList[]>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IProps | null>(null);
  const [paymentableId, setPaymentableId] = useState<IpaymentableItem[]>([]);
  // const [discount, setDiscount] = useState<number>(0);
  const Toast = Toaster();

  useEffect(() => {
    setLoading(true);
    try {
      if (user?.user_admin_id) {
        setLoading(true);
        const urls = [
          `${url}/api/atab/payment/get/all/paymentable/item`,
          `${url}/api/atab/user-member/company-name/get-all/status-or-all/all`,
        ];
        Promise.all(
          urls.map((url) => fetch(url).then((response) => response.json()))
        ).then((data) => {
          if (data?.length) {
            setAllPaymentableItem(data[0].data);
            setUserMember(data[1].data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [user.user_admin_id]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!membersId.user_member_id) {
      Toast.fire({
        icon: 'error',
        title: `Select a member`,
      });
    } else if (paymentableId?.length === 0) {
      Toast.fire({
        icon: 'error',
        title: `Select a Invoice item`,
      });
    } else {
      const selectedMember = paymentableId.map((member: IpaymentableItem) => {
        return member.atab_paymentable_item_id;
      });

      let body = {
        atab_payment_invoice_user_member_id: membersId.user_member_id,
        atab_payment_invoice_paymentable_items: selectedMember,
        // atab_payment_invoice_discount_amount: discount,
      };

      setisMsgSent(true);
      try {
        const { data } = await axios.post(
          `${url}/api/atab/invoice/create/for-admin?user_admin_id=${user.user_admin_id}`,
          body
        );

        if (data.success) {
          setAllInvoices((pre) => [
            {
              atab_payment_invoice_id: data.data.atab_payment_invoice_id,
              user_member_company_name: membersId.user_member_company_name,
              atab_payment_invoice_issue_date: new Date(),
              atab_payment_invoice_status: 'pending',
              atab_payment_invoice_grand_total:
                data.data.atab_payment_invoice_grand_total,
              atab_payment_invoice_user_member_id:
                body.atab_payment_invoice_user_member_id,
              updated_by_admin_id: user.user_admin_id,
              updated_by_admin_name: user.user_admin_name,
            },
            ...pre,
          ]);
          handleClose();
          Toast.fire({
            icon: 'success',
            title: `Invoice Successfully created`,
          });
          setisMsgSent(false);
          setPaymentableId(null);
          setMembersId(null);
        } else {
          setPaymentableId(null);
          setisMsgSent(false);
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: err.response?.data?.message,
        });
        setisMsgSent(false);
        setPaymentableId(null);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>ATF - Create invoice</title>
      </Helmet>

      <Box
        sx={{
          mx: 4,
          mt: 2,
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={{ xs: 2 }} mb={2}>
          <Grid xs={12} lg={12} item>
            <Autocomplete
              placeholder='Select Member'
              value={membersId}
              onChange={(event: any, newValue: any) => {
                setMembersId(newValue);
              }}
              id='controllable-states-demo'
              options={userMember}
              getOptionLabel={(option) => option.user_member_company_name}
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant='filled'
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label='Select Company' />
              )}
            />
          </Grid>
          {/* 
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              id='outlined-password-input'
              label='Discount'
              type='number'
              onChange={(e) => setDiscount(parseFloat(e.target.value))}
            />
          </Grid> */}
          <Grid xs={12} item>
            <Autocomplete
              multiple
              id='tags-standard'
              onChange={(e, mainValue: any) => setPaymentableId(mainValue)}
              options={allPaymentableItem}
              getOptionLabel={(option: IpaymentableItem) =>
                option.atab_paymentable_item_name +
                ' (' +
                option.atab_paymentable_item_bdt_amount +
                'tk)'
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select invoice items'
                  placeholder='More...'
                />
              )}
            />
          </Grid>
          <Grid lg={12} item>
            {!isMSgsent ? (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                fullWidth
              >
                Create a invoice
              </Button>
            ) : (
              <LoadingButton
                loading
                loadingIndicator='Creating...'
                variant='contained'
                disabled
                fullWidth
              >
                <span>Creating...</span>
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateInvoice;
