import { useState, createContext } from 'react';
// type SidebarContext = {
//   sidebarToggle: any;
//   toggleSidebar: () => void;
//   closeSidebar: () => void;
// };

export const SidebarContext = createContext<any>({} as any);

export const SidebarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
