import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IFairVisitorList } from '../Types/AllReportTypes';
import { getStatusLabel } from 'src/Utils/Combined';

type IProps = {
  fairVisitor: IFairVisitorList;
};

const FairVisitorTableRow = ({ fairVisitor }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_payment_invoice_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {moment(
            fairVisitor.visitor_payment_invoice_issue_date,
            'HH:mm:ss'
          ).format('YYYY-MM-DD, hh:mm A')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_first_name}
          {'  '}
          {fairVisitor.visitor_last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_payment_invoice_total_amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {moment(
            fairVisitor.visitor_payment_invoice_transaction_date,
            'HH:mm:ss'
          ).format('YYYY-MM-DD, hh:mm A')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairVisitor.visitor_payment_invoice_payment_method}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant='body1'
          fontWeight='bold'
          color='text.primary'
          display='inline'
          sx={{ pr: 1 }}
          gutterBottom
        >
          {getStatusLabel(fairVisitor.visitor_payment_invoice_status)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default FairVisitorTableRow;
