import { ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Tooltip,
  IconButton,
  useTheme,
} from '@mui/material';
import FairInvoiceByFairMemberTableRow from './FairInvoiceByFairMemberTableRow';
import { IFairInvoiceByFairMember } from 'src/Types/module/fairInvoice/fairInvoiceByFairMemberType';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';

const FairInvoiceByFairMemberTable = ({
  allAdmin,
  loading,
  setStatus,
  status,
  setReload,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const theme = useTheme();

  const statusOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'paid',
      name: 'Paid',
    },
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'rejected',
      name: 'Rejected',
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allAdmin, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '320px',
              gap: '15px',
            }}
          >
            <Tooltip onClick={() => navigate(-1)} title='Go to Back' arrow>
              <IconButton
                sx={{
                  '&:hover': { background: theme.colors.error.lighter },
                  color: theme.palette.error.main,
                }}
                color='inherit'
                size='small'
              >
                <KeyboardBackspaceIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label='Status'
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title='Fair member invoice  List'
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Invoice issue date</TableCell>
              <TableCell>Total amount</TableCell>
              <TableCell>Payment method</TableCell>
              <TableCell>Transaction date</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Updated by</TableCell>
              <TableCell>Invoice file</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableLoader />
          ) : allAdmin.length ? (
            <TableBody>
              {paginatedInvoice.map((allAdmin: IFairInvoiceByFairMember) => {
                return (
                  <FairInvoiceByFairMemberTableRow
                    key={allAdmin.fair_invoice_id}
                    allAdmin={allAdmin}
                    setReload={setReload}
                  />
                );
              })}
            </TableBody>
          ) : <TableNotFound />}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component='div'
          count={allAdmin.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default FairInvoiceByFairMemberTable;
