import { useState } from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { IPermission } from 'src/Types/module/roleAndPermission/roleAndPermissionType';

interface IProps {
  permission: IPermission;
}

const PermissionTableRow = ({ permission }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {permission.permissions_section
              .toLowerCase()
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </Typography>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              p: 1,
              m: 1,
              bgcolor: 'background.paper',
              maxWidth: 500,
              borderRadius: 1,
            }}
          >
            {permission.permissions.map((sPermission) => {
              const colors = [
                'rgba(225, 184, 213, 0.3)',
                'rgba(186, 227, 213, 0.3)',
                'rgba(186, 195, 232, 0.3)',
                'rgba(130, 190, 201, 0.3)',
                'rgba(130, 190, 134, 0.3)',
                'rgba(24, 132, 248, 0.3)',
              ];
              const randomColor =
                colors[Math.floor(Math.random() * colors.length)];

              return (
                <Box
                  key={sPermission.permissions_id}
                  sx={{
                    m: 0.5,
                    px: 1,
                    py: 0.5,
                    border: 1,
                    borderColor: randomColor,
                    borderRadius: '5px',
                    backgroundColor: randomColor,
                  }}
                >
                  <span>
                    {sPermission.permissions_name
                      .toLowerCase()
                      .split(' ')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(' ')}
                  </span>
                </Box>
              );
            })}
          </Box>
        </TableCell>

        <TableCell>
          {/* <Link to={`/admin-list/single-admin/${permission.permissions_id}`}> */}
          {/* <Tooltip title='view' arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color='inherit'
              size='small'
            >
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </Tooltip> */}
          {/* </Link> */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default PermissionTableRow;
