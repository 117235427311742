import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { url } from 'src/Helpers/utils/Constant';

function Events({ onEventSelect }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState('');

  useEffect(() => {
    fetch(`${url}/api/fair/event/get/all/all`)
      .then((response) => response.json())
      .then((data) => {
        setEvents(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleEventChange = (event) => {
    const selectedEventId = event.target.value;
    setSelectedEvent(selectedEventId);
    onEventSelect(selectedEventId);
  };

  return (
    <div>
      <FormControl style={{ width: '200px' }}>
        <InputLabel>Select Event</InputLabel>
        <Select onChange={handleEventChange} value={selectedEvent}>
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={20} /> Loading...
            </MenuItem>
          ) : events.length > 0 ? (
            events.map((event) => (
              <MenuItem key={event.event_id} value={event.event_id}>
                {event.event_title?.slice(0, 15)}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No events available</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

export default Events;
