import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, DialogActions, Divider, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type IProps = {
  children: React.ReactNode;
  showModal: boolean;
  setShowModal: any;
  modalData: any;
};

const ModalsWrapper = ({
  children,
  showModal,
  setShowModal,
  modalData,
}: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        open={showModal}
        onClose={() => {
          return setShowModal(!showModal);
        }}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle>
          <Typography variant='h4' sx={{ mb: 2 }}>
            {modalData.title}
          </Typography>
          <Divider />
        </DialogTitle>
        {children}
        <DialogActions>
          <Button autoFocus onClick={() => setShowModal(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalsWrapper;
