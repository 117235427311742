import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Toaster from '../../../Toaster/Toaster';
import { url } from '../../../Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { Iadmin } from 'src/Types/module/admin/adminTypes';
import { IRoleList } from 'src/Types/module/roleAndPermission/roleAndPermissionType';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

interface IProps {
  handleClose: Dispatch<SetStateAction<boolean>>;
  setAllAdmins: Dispatch<SetStateAction<Iadmin[]>>;
}

const CreateAdmin = ({ handleClose, setAllAdmins }: IProps) => {
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zone, setZone] = React.useState<number>(0);
  const [role, setRole] = React.useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  const [zoneName, setZoneName] = useState('');
  const [allRole, setAllRole] = useState<IRoleList[]>([]);
  const { user } = useAuthContext();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/roles`)
          .then(function (res) {
            if (res.data.success) {
              setAllRole(res.data.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setAllRole([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (inputs: any) => {
    setIsLoading(true);
    inputs.user_admin_zone = zone;
    try {
      const { user_admin_confirm_password, user_admin_photo, ...body } = inputs;
      const bodyFormData = new FormData();
      for (const key in body) {
        bodyFormData.append(key, body[key]);
      }
      bodyFormData.append('user_admin_photo', user_admin_photo[0]);
      bodyFormData.append('user_admin_role', role.id);

      const { data } = await axios.post(
        `${url}/api/atab/admin/create/an/admin?user_admin_id=${user.user_admin_id}`,
        bodyFormData
      );

      if (data.success) {
        setAllAdmins((prev) => [
          {
            user_admin_id: data.data.user_admin_id,
            user_admin_name: inputs.user_admin_name,
            user_admin_phone: inputs.user_admin_phone,
            user_admin_email: inputs.user_admin_email,
            user_admin_photo: data.data.user_admin_photo,
            user_admin_role: role.id,
            user_admin_role_name: role.name.split('_')[1],
            user_admin_status: 'pending',
            // user_admin_zone: zone,
            // user_admin_zone_name: zoneName,
          },
          ...prev,
        ]);
        Toast.fire({
          icon: 'success',
          title: `Admin Created Successfully`,
        });
        reset();
        handleClose(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);

      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>ATF - Create Admin</title>
      </Helmet>

      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          m: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='name'
              label='User Admin Name'
              {...register('user_admin_name')}
              type='text'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='phone'
              label='Phone'
              {...register('user_admin_phone')}
              type='number'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email'
              {...register('user_admin_email')}
              type='email'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth required margin='normal'>
              <InputLabel id='demo-simple-select-label'>Role</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={role.name}
                label='Role'
                onChange={(e) =>
                  setRole({
                    id: e.target.value.toString().split('_')[0],
                    name: e.target.value,
                  })
                }
              >
                {allRole.map((sRole) => {
                  return (
                    <MenuItem
                      key={sRole.roles_id}
                      value={sRole.roles_id + '_' + sRole.roles_name}
                    >
                      {sRole.roles_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='file'
              label='Choose Admin Photo'
              InputLabelProps={{ shrink: true }}
              {...register('user_admin_photo')}
              autoComplete='file'
              type='file'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              {...register('user_admin_password')}
              label='Password'
              type={`${showPassword ? 'text' : 'password'}`}
              id='password'
              autoComplete='current-password'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              {...register('user_admin_confirm_password')}
              label='Confirm Password'
              type={`${showPassword ? 'text' : 'password'}`}
              id='password2'
              autoComplete='current-password'
              variant='outlined'
            />
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              onClick={() => setShowPassword(!showPassword)}
              value={showPassword}
              color='primary'
            />
          }
          label='Show Password'
        />
        {!isLoading ? (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Create Admin
          </Button>
        ) : (
          <LoadingButton
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Creating...
          </LoadingButton>
        )}
      </Box>
    </>
  );
};

export default CreateAdmin;
