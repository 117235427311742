import { Card, Container, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import Events from './Events';
import EventVisitorTable from './EventVisitorTable';
import ExcelGeneratorforEventAttendVisitor from 'src/components/ExcelGenerator/ExcelGeneratorforEventAttendVisitor';
import ExcelGeneratorforEventJoinedVisitor from 'src/components/ExcelGenerator/ExcelGeneratorforEventJoinedVisitor';

const EventVisitor = () => {
  const [eventId, setEventId] = useState('');
  const [allAttendVisitor, setAllAttendVisitor] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEventSelect = (selectedEventId) => {
    setEventId(selectedEventId);
  };

  useEffect(() => {
    if (!eventId) {
      return;
    }

    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/report/get/all/registered-visitor/by-event/${eventId}`
        );
        if (data.success) {
          setAllAttendVisitor(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId]);

  const fetchFairEntryData = async () => {
    let apiUrl = `${url}/api/fair/report/get/all/registered-visitor/by-event/${eventId}`;

    try {
      const response = await axios.get(apiUrl);
      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      } else {
        throw error;
      }
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <div
              style={{
                textAlign: 'end',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Events onEventSelect={handleEventSelect} />
              <ExcelGeneratorforEventJoinedVisitor
                getDownloadData={fetchFairEntryData}
              />
            </div>
            <EventVisitorTable
              loading={loading}
              entryFairVisitorList={allAttendVisitor}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventVisitor;
