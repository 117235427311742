// import { ChangeEvent, useEffect, useState } from 'react';
// import {
//   Divider,
//   Box,
//   Card,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TablePagination,
//   TableRow,
//   TableContainer,
//   CardHeader,
//   Button,
//   useTheme,
//   useMediaQuery,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from '@mui/material';

// import { applyPagination } from 'src/Utils/CommonAllShowUtils';
// import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
// import axios from 'axios';
// import { url } from 'src/Helpers/utils/Constant';
// import PaymentTableRow from './PaymentTableRow';
// import CreatePayment from './CreatePayment';
// import { IPayment } from './paymentTypes';

// const AllPaymentlist = () => {
//   const [allPayment, setAllPayment] = useState([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [page, setPage] = useState<number>(0);
//   const [limit, setLimit] = useState<number>(25);
//   const [searchValue, setSearchValue] = useState<string>('');

//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

//   // Modal
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   useEffect(() => {
//     setLoading(true);
//     (async function () {
//       try {
//         const { data } = await axios.get(
//           `${url}/api/fair/event/payment-method`
//         );
//         if (data.success) {
//           setAllPayment(data.data);
//           setLoading(false);
//         } else {
//           setLoading(false);
//         }
//       } catch (error) {
//         setLoading(false);
//       }
//     })();
//   }, [status, searchValue]);

//   const handlePageChange = (event: any, newPage: number): void => {
//     setPage(newPage);
//   };

//   const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
//     setLimit(parseInt(event.target.value));
//   };

//   const paginatedInvoice = applyPagination(allPayment, page, limit);

//   return (
//     <Box sx={{ m: 2 }}>
//       <Card>
//         <CardHeader
//           action={
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 gap: 2,
//               }}
//             >
//               {' '}
//               <Button onClick={() => handleOpen()} variant='contained'>
//                 Create Payment Type
//               </Button>
//             </Box>
//           }
//           title='Payment Type  List'
//         />

//         <Divider />
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>ID</TableCell>
//                 <TableCell>MFS Type</TableCell>
//                 <TableCell>Phone</TableCell>
//                 <TableCell>Status</TableCell>
//                 <TableCell>Created</TableCell>
//               </TableRow>
//             </TableHead>

//             {loading ? (
//               <TableLoader />
//             ) : allPayment.length ? (
//               <TableBody>
//                 {paginatedInvoice.map((singlePayment: IPayment) => {
//                   return (
//                     <PaymentTableRow
//                       key={singlePayment.id}
//                       payment={singlePayment}
//                       setAllPayment={setAllPayment}
//                       allPayment={allPayment}
//                     />
//                   );
//                 })}
//               </TableBody>
//             ) : (
//               <TableNotFound />
//             )}
//           </Table>
//         </TableContainer>
//         <Box p={2}>
//           <TablePagination
//             component='div'
//             count={allPayment.length}
//             onPageChange={handlePageChange}
//             onRowsPerPageChange={handleLimitChange}
//             page={page}
//             rowsPerPage={limit}
//             rowsPerPageOptions={[25, 50, 75, 100]}
//           />
//         </Box>
//         {/* Modal */}
//         <Dialog
//           fullScreen={fullScreen}
//           open={open}
//           maxWidth='md'
//           onClose={handleClose}
//           aria-labelledby='responsive-dialog-title'
//         >
//           <DialogContent>
//             <Box sx={{ mb: 2 }}>
//               <Typography sx={{ mb: 2 }}>Create Payment Type</Typography>
//             </Box>
//             <CreatePayment
//               handleClose={handleClose}
//               allPayment={allPayment}
//               setAllPayment={setAllPayment}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button autoFocus onClick={handleClose}>
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Card>
//     </Box>
//   );
// };

// export default AllPaymentlist;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import axios from 'axios';
import moment from 'moment';
import { url } from 'src/Helpers/utils/Constant';
import CreatePayment from './CreatePayment';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { getStatusLabel } from 'src/Utils/Combined';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { EditNotifications } from '@mui/icons-material';
import UpdatePaymentType from './UpdatePaymentType';

// Other imports...

const AllPaymentlist = ({ eventId }: any) => {
  const theme = useTheme();
  const [allVisitorJoin, setAllVisitorJoin] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);

  const [modalVisibilities, setModalVisibilities] = useState<boolean[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  useEffect(() => {
    setModalVisibilities(new Array(allVisitorJoin.length).fill(false));
  }, [allVisitorJoin]);

  const toggleModalVisibility = (index: number) => {
    const updatedVisibilities = [...modalVisibilities];
    updatedVisibilities[index] = !updatedVisibilities[index];
    setModalVisibilities(updatedVisibilities);
  };

  const [allPayment, setAllPayment] = useState([]);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);

    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/event/payment-method`
        );

        if (data.success) {
          setAllVisitorJoin(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId, reload]);

  const handleEditClick = (id: number) => {
    setSelectedId(id);
    toggleModalVisibility(id);
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Card>
          <CardHeader
            action={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <Button onClick={() => handleOpen()} variant='contained'>
                  Create Payment Type
                </Button>
              </Box>
            }
            title='Payment Type  List'
          />

          <Divider />

          <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth='md'
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ mb: 2 }}>Create Payment Type</Typography>
              </Box>
              <CreatePayment
                handleClose={handleClose}
                allPayment={allPayment}
                setAllPayment={setAllPayment}
              />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
        <TableContainer sx={{ marginBottom: 10, marginTop: '20px' }}>
          <Table>
            <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
              <TableRow>
                <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                  ID
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align='center'
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align='center'
                >
                  MFS TYPE
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align='center'
                >
                  STATUS
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align='center'
                >
                  CREATED AT
                </TableCell>
                <TableCell
                  sx={{ color: theme.colors.alpha.white[100] }}
                  align='center'
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {allVisitorJoin.length ? (
                    <>
                      {allVisitorJoin.map((details, index) => (
                        <TableRow hover key={details.id}>
                          <TableCell align='center'>{details.id}</TableCell>
                          <TableCell align='center'>{details.phone}</TableCell>
                          <TableCell align='center'>
                            {details.mfs_type || 'N/A'}
                          </TableCell>
                          <TableCell align='center'>
                            {getStatusLabel(
                              (details.status === 1 ? 'Active' : 'Inactive') ||
                                'N/A'
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            {moment(details.created_at).format('YYYY-MM-DD') ||
                              'N/A'}
                          </TableCell>
                          <TableCell>
                            <Tooltip title='Edit'>
                              <IconButton
                                onClick={() => handleEditClick(details.id)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <EditNotifications />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <ModalsWrapper
                            showModal={modalVisibilities[details.id]}
                            setShowModal={(value) => {
                              const updatedVisibilities = [
                                ...modalVisibilities,
                              ];
                              updatedVisibilities[details.id] = value;
                              setModalVisibilities(updatedVisibilities);
                            }}
                            modalData={{ title: 'Update Status' }}
                          >
                            <UpdatePaymentType
                              id={details.id} // Pass the selected id to the UpdatePaymentType component
                              details={details}
                              setReload={setReload}
                            />
                          </ModalsWrapper>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AllPaymentlist;
