import { Container, Grid } from "@mui/material";
import React from "react";
import FairInvoiceByFairMemberShow from "./FairInvoiceByFairMemberShow";

const FairInvoiceByFairMember = () => {
  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <FairInvoiceByFairMemberShow />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FairInvoiceByFairMember;
