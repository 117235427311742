import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IFairMemberInvoice } from '../Types/AllReportTypes';
import { getStatusLabel } from 'src/Utils/Combined';

type IProps = {
  fairMemberInvoice: IFairMemberInvoice;
};

const FairMemberInvoiceTableRow = ({ fairMemberInvoice }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairMemberInvoice.fair_invoice_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {moment(fairMemberInvoice.invoice_issue_date, 'HH:mm:ss').format(
            'YYYY-MM-DD, hh:mm A'
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairMemberInvoice.user_fair_member_contact_name}
          {'  '}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairMemberInvoice.total_amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {moment(
            fairMemberInvoice.fair_invoice_transaction_date,
            'HH:mm:ss'
          ).format('YYYY-MM-DD, hh:mm A')}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          variant='body1'
          fontWeight='bold'
          color='text.primary'
          display='inline'
          sx={{ pr: 1 }}
          gutterBottom
        >
          {getStatusLabel(fairMemberInvoice.fair_invoice_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1' color='text.primary' gutterBottom noWrap>
          {fairMemberInvoice.fair_invoice_remark}
          {'  '}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default FairMemberInvoiceTableRow;
