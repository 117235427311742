import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useParams } from 'react-router';

import { IGuest } from 'src/Types/module/guestAndSpecker/guestAndSpeaker';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import Toaster from 'src/Toaster/Toaster';
import { url } from 'src/Helpers/utils/Constant';
import { muiStyles } from 'src/Utils/Styles';
import { IGMatrials } from './matrialstype';

interface IProps {
  materials: IGMatrials;
  setMaterials: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalData: any;
}

const UpdateSpeakerMaterials: React.FC<IProps> = ({
  materials,
  setMaterials,
  setShowModal,
  modalData,
}) => {
  const { register, reset, handleSubmit, setValue } = useForm();
  const Toast = Toaster();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    try {
      const changedFields: any = {};

      if (inputs.sm_details !== materials?.sm_details) {
        changedFields.sm_details = inputs.sm_details;
      }
      if (inputs.sm_visiblity !== materials?.sm_visiblity) {
        changedFields.sm_visiblity = inputs.sm_visiblity;
      }
      if (inputs.sm_file && inputs.sm_file[0]) {
        changedFields.sm_file = inputs.sm_file[0];
      }

      const bodyFormData = new FormData();
      for (const key in changedFields) {
        bodyFormData.append(key, changedFields[key]);
      }

      const { data } = await axios.patch(
        `${url}/api/fair/guest-speaker/update/materials/${modalData.sm_id}`,
        bodyFormData
      );

      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: `${data.message}`,
        });
        setLoading(false);
        setShowModal(false);
        setMaterials((prevMaterials) => ({
          ...prevMaterials,
          ...changedFields,
        }));
      } else {
        Toast.fire({
          icon: 'error',
          title: `${data.message}`,
        });
        setLoading(false);
      }
      reset();
    } catch (err: any) {
      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>ATF - Update Speaker Materials</title>
      </Helmet>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component='form'
        sx={{ p: 2, mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              id='name'
              label='Details'
              {...register('sm_details')}
              type='text'
              autoFocus
              variant='outlined'
            />
          </Grid>

          <Grid xs={12} sm={6} item>
            <FormControl variant='outlined' fullWidth sx={{ mt: 2 }}>
              <InputLabel id='demo-simple-select-filled-label'>
                Status
              </InputLabel>
              <Select
                labelId='demo-simple-select-filled-label'
                id='demo-simple-select-filled'
                {...register('sm_visiblity')}
              >
                <MenuItem value={1}>Published</MenuItem>
                <MenuItem value={0}>Unpublished</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              id='outlined-required'
              type='file'
              label='Upload File'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('sm_file')}
            />
          </Grid>
        </Grid>

        {loading ? (
          <Button
            disabled
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Updating...
          </Button>
        ) : (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Update Materials
          </Button>
        )}
      </Box>
    </>
  );
};

export default UpdateSpeakerMaterials;
