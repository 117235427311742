import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useParams } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';
import EditIcon from '@mui/icons-material/Edit';
import UpdateGuestAndSpeaker from './UpdateGuestAndSpeaker';
import SingleGuestAndSpeakerDetails from './SingleGuestAndSpeakerDetails';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';

const SingleGuestAndSpeaker = () => {
  const [guest, setGuest] = useState();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/fair/get/single/guest-speaker/guest-speaker-id/${id}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setGuest(data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id, showModal]);

  return (
    <div>
      <Helmet>
        <title>ATF - Single Guest & Speaker</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Typography variant='h4' color='text.primary'>
                Guest & Speaker
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => setShowModal(!showModal)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider />
            {/* Guest and Speaker Details */}
            {loading ? (
              <SuspenseLoader />
            ) : (
              <SingleGuestAndSpeakerDetails guest={guest} />
            )}

            {/* UPDATE */}
            <ModalsWrapper
              showModal={showModal}
              setShowModal={setShowModal}
              modalData={{ title: 'Update Guest and Speaker' }}
            >
              <UpdateGuestAndSpeaker
                guest={guest}
                setGuest={setGuest}
                setShowModal={setShowModal}
              />
            </ModalsWrapper>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default SingleGuestAndSpeaker;
