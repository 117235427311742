import { TableCell, TableRow, Typography } from '@mui/material';
import { IAuditAndTrail } from 'src/Types/module/auditAndTrail/auditAndTrail';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface IProps {
  sAudit: IAuditAndTrail;
}

const AuditAndTrailTableRow = ({ sAudit }: IProps) => {
  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {sAudit.audit_trail_id}
          </Typography>
        </TableCell>
        <TableCell>
          <img
            width={50}
            height={50}
            style={{
              borderRadius: '80%',
              marginRight: '10px',
            }}
            src={
              sAudit.user_admin_photo
                ? `${imgUrl}/admin_files/${sAudit.user_admin_photo}`
                : '/static/images/avatars/avatar2.png'
            }
            alt=''
          />
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            <Link
              to={`/admin-list/single-admin/${sAudit.audit_trail_admin_id}`}
            >
              {sAudit.user_admin_name}
            </Link>
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant='body1'
            color='text.primary'
            gutterBottom
            sx={{ width: { md: '200px', xl: '200px', lg: '200px' } }}
          >
            {sAudit.audit_trail_details}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant='body1'
            color='text.primary'
            gutterBottom
            noWrap
            sx={{ width: { md: '300px', xl: '300px', lg: '300px' } }}
          >
            {moment(sAudit.audit_trail_created_at).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            )}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AuditAndTrailTableRow;
