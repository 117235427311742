import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { getStatusLabelFairList } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { IAdminFair } from "src/Types/module/fairInvoice/fairInvoiceType";
type IProps = {
  allAdmin: IAdminFair;
};

const FairTableRow = ({ allAdmin }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex" }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
              }}
              src={
                allAdmin.fair_main_banner
                  ? `${imgUrl}/training_trainee_files/${allAdmin.fair_main_banner}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt='Fair Banner image'
            />
            <Typography
              variant='body1'
              color='text.primary'
              gutterBottom
              noWrap
            >
              {allAdmin.fair_name
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{getStatusLabelFairList(allAdmin.fair_status)}</TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.fair_start_date).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {moment(allAdmin.fair_end_date).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {allAdmin.fair_location
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title='view' arrow>
            <Link to={`/fair/list/${allAdmin.fair_id.toString()}`}>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FairTableRow;
